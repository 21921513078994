import { React, useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import axios from 'axios';
import SsembleSpinner from './../ssembleSpinner.js';
import { instagram_icon, tiktok_icon, youtube_icon } from './../../assets/social_icons';
import { linkicon, Eyeeye, LinkBreak, ArrowCounterClockwiseRetry } from './../../assets/utility_icons';
import TagsInput from './../../components/ShortsMaker/TagsInput.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import Modal from "react-bootstrap/Modal";
import InstagramTutorial from './../ShortsMaker/AuthParts/InstagramTutorial'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import VideoPlayer from '../VideoPlayer';
import { useLocation } from 'react-router-dom';

import NoConnectedAccounts from '../ShortsMaker/AccountTemplateParts/NoConnectedAccounts.js'
import ConnectedAccounts from '../ShortsMaker/AccountTemplateParts/ConnectedAccounts.js'
import TopInfoBar from './../TopInfoBar';
const AuthSectionMultiSelect = ({ video_url123 }) => {

    const MAX_TITLE_LENGTH = 100;
    const MAX_DESCRIPTION_LENGTH = 5000;

    const location = useLocation();
    // console.log("video_url from iframe", video_url123);
    var video_url = '';
    if (video_url123 !== null) {
        video_url = video_url123;
    } else {
        const ShortsMakerField = location.state?.ShortsMakerField;
        // console.log("ShortsMakerField data on upload page", ShortsMakerField);
        video_url = ShortsMakerField?.video_url;
    }
    // console.log("Video url on upload page", video_url);
    // const video_url = "https://storage.googleapis.com/klap-renders/edbdeadc-0ce7-4503-9ced-8250d3ad72f1-d1bb538c-8a9e-4ddc-b8ef-d36c9d9ee801.mp4";
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isInstaLoggedIn, setIsInstaLoggedIn] = useState(false);
    const [isTiktokLoggedIn, setIsTiktokLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // const [videoFile, setVideoFile] = useState(null);

    const [isUploading, setIsUploading] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');
    const [alertShow, setAlertShow] = useState(false);
    const [userInfo, setUserInfo] = useState({
        google: {},
        instagram: {},
        tiktok: {}
    });

    const [videoTitle, setVideoTitle] = useState('');
    const [isYoutubeUploading, setIsYoutubeUploading] = useState(false);
    const [isInstagramUploading, setIsInstagramUploading] = useState(false);
    const [isTiktokUploading, setIsTiktokUploading] = useState(false);


    const [videoDescription, setVideoDescription] = useState('');
    const [videoTags, setVideoTags] = useState([]);
    const [IsTermsAgreed, setTermsAgreed] = useState(false);

    const [accessSpecifier, setAccessSpecifier] = useState('public');

    // tiktok form metas
    const [tiktokvideoTitle, settiktokVideoTitle] = useState('');
    const [tiktokvideoTags, settiktokVideoTags] = useState([]);
    const [tiktokaccessSpecifier, settiktokAccessSpecifier] = useState('');

    useEffect(() => {
        const handleMessage = (event) => {
            // console.log('Received message:', event.data);
            if (event.data.action === 'sendData') {
                const { title, description } = event.data.data;
                console.log("Title from event: " + title);
                // console.log("description from event: " + description);
                setVideoTitle(title);
                // setVideoDescription(description);
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    const [tiktokDisclose, setTiktokDisclose] = useState(false);
    const [tiktokYourBrand, setTiktokYourBrand] = useState(false);
    const [tiktokBrandedContent, setTiktokBrandedContent] = useState(false);
    const [checkboxState, setCheckboxState] = useState({
        checkbox_yt: false,
        checkbox_insta: false,
        checkbox_tiktok: false
    });

    const [youtubeData, setYoutubeData] = useState({
        videoId: null,
        videoUrl: '',
        uploadStatus: false
    });

    const [instagramData, setInstagramData] = useState({
        videoId: null,
        videoUrl: '',
        uploadStatus: false
    });


    const [tiktokData, setTiktokData] = useState({
        videoId: null,
        videoUrl: '',
        uploadStatus: false
    });

    // Instagram form metas
    const [instagramvideoTitle, setinstagramVideoTitle] = useState('');
    const [instagramvideoTags, setinstagramVideoTags] = useState([]);


    const [comment, setComment] = useState(false);
    const [duet, setDuet] = useState(false);
    const [stitch, setStitch] = useState(false);

    const [show, setShow] = useState(false);
    const [addPlateformModel, setAddPlateformModel] = useState(false);
    const handlePlateformClose = () => setAddPlateformModel(false);
    const handleClose = () => setShow(false);
    const handlesetShow = () => setShow(true);
    const apiUrl = process.env.REACT_APP_API_URL;

    const MAX_VIDEO_DURATION_SEC = 300;
    const [videoUrl, setVideoUrl] = useState(''); // State to store video URL

    const [publishButtonDisabled, setPublishButtonDisabled] = useState(false);

    useEffect(() => {
        // console.log("User Info Changed", userInfo);
    }, [userInfo]);

    useEffect(() => {
        // Disable publish button if Disclose video content is checked and neither Your Brand nor Branded Content is selected
        if (tiktokDisclose && !tiktokYourBrand && !tiktokBrandedContent) {
            setPublishButtonDisabled(true);
        } else {
            setPublishButtonDisabled(false);
        }
    }, [tiktokYourBrand, tiktokBrandedContent, tiktokDisclose]);


    const checkVideoDuration = async (videoUrl, maxDuration) => {
        // Create a video element
        const videoElement = document.createElement('video');

        // Set the source of the video element
        videoElement.src = videoUrl;

        // Ensure that the metadata is loaded
        await new Promise((resolve, reject) => {
            videoElement.addEventListener('loadedmetadata', () => {
                // Once metadata is loaded, resolve the promise
                resolve();
            });

            // Handle errors if the video fails to load
            videoElement.addEventListener('error', (error) => {
                reject(error);
            });
        });

        // Access the duration of the video
        const videoDuration = videoElement.duration;
        // Check if the video duration is within the maximum allowed duration
        return videoDuration <= maxDuration;
    };


    // Define your privacy level options as constants
    const PRIVACY_OPTIONS = {
        SELF_ONLY: 'SELF_ONLY',
        PUBLIC_TO_EVERYONE: 'PUBLIC_TO_EVERYONE',
        MUTUAL_FOLLOW_FRIENDS: 'MUTUAL_FOLLOW_FRIENDS',
    };

    // Function to check if an option should be disabled
    const isOptionDisabled = (option) => {
        // console.log("user privacy levels ------------------", userInfo?.tiktok?.data?.privacy_level_options);
        return !userInfo?.tiktok?.data?.privacy_level_options || !userInfo?.tiktok?.data?.privacy_level_options.includes(option);
    };


    useEffect(() => {
        // console.log('userInfo updated data', userInfo);
    }, [userInfo])
    const handleChange = (event) => {
        setCheckboxState({
            ...checkboxState,
            [event.target.name]: event.target.checked
        });
    };



    const handleDisconnect = (platform) => {
        // // console.log(`Attempting to disconnect with ${platform}`);
        switch (platform) {
            case 'instagram':
                localStorage.removeItem('instagramToken');
                localStorage.removeItem('instagramAuthToken');
                localStorage.removeItem('platform');
                setIsInstaLoggedIn(false);
                break;
            case 'tiktok':
                localStorage.removeItem('tiktokToken');
                localStorage.removeItem('tiktokAuthToken');
                localStorage.removeItem('platform');
                setIsTiktokLoggedIn(false);
                break;
            case 'google':
                localStorage.removeItem('googleToken');
                setIsLoggedIn(false);
                break;
            default:
                break;
        }
    };

    let popupWindow = null;
    const handleLogin = (service) => {
        setShow(false);
        handlePlateformClose(); // close the popup.
        let serviceUrl = '';
        switch (service) {
            case 'google':
                serviceUrl = '/auth/google/url';
                break;
            case 'instagram':
                serviceUrl = '/auth/instagram/url';
                break;
            case 'tiktok':
                serviceUrl = '/auth/tiktok/url';
                break;
            default:
                break;
        }

        axios.get(apiUrl + serviceUrl)
            .then(response => {
                const authUrl = response.data.authUrl;
                popupWindow = window.open(
                    authUrl,
                    'targetWindow',
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=600,
                    height=600`
                );

            })
            .catch(error => {
                console.error(`Failed to retrieve login URL for ${service}:`, error);
            });
    };


    const fetchUserInfo = (platform) => {
        const token = localStorage.getItem(`${platform}Token`);
        // console.log("token", token);
        if (!token) return;

        let url;
        if (platform === 'google') {
            const googleToken = localStorage.getItem('googleToken');
            url = `${apiUrl}/auth/userinfo?access_token=${googleToken}`;
        } else if (platform === 'instagram') {
            const instagramToken = localStorage.getItem('instagramToken');
            url = `${apiUrl}/auth/instagram/userinfo?access_token=${instagramToken}`;
        } else if (platform === 'tiktok') {
            url = `${apiUrl}/auth/tiktok/userinfo?access_token=${token}`;
        }

        axios.get(url)
            .then(response => {
                // console.log("p the", userInfo);
                setUserInfo(prevState => ({
                    ...prevState,
                    [platform]: response.data
                }));
            })
            .catch(error => {
                console.error(`Error fetching ${platform} user info:`, error);
            });
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchUserInfo('google');
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isInstaLoggedIn) {
            fetchUserInfo('instagram');
        }
    }, [isInstaLoggedIn]);

    useEffect(() => {
        if (isTiktokLoggedIn) {
            fetchUserInfo('tiktok');
        }
    }, [isTiktokLoggedIn]);


    // const checkLoginStatus = () => {
    //     setIsLoading(true);
    //     const googleToken = localStorage.getItem('googleToken');
    //     const instagramToken = localStorage.getItem('instagramAuthToken');
    //     const tiktokToken = localStorage.getItem('tiktokAuthToken');

    //     // Check if at least one token is set
    //     if (googleToken || instagramToken || tiktokToken) {
    //         axios.get(`${apiUrl}/auth/login/status`, {
    //             params: {
    //                 token: googleToken, // Google token
    //                 insta_token: instagramToken, // Instagram token
    //                 tiktok_token: tiktokToken // TikTok token
    //             }
    //         })
    //             .then(response => {
    //                 // console.log("Login response-------", response);
    //                 if (response?.data?.newGoogleToken) {
    //                     localStorage.setItem('googleToken', response.data.newGoogleToken);
    //                 }
    //                 setIsLoggedIn(response.data.isLoggedIn);
    //                 setIsInstaLoggedIn(response.data.isInstaLoggedIn);
    //                 // if (response?.data?.newTiktokToken) {
    //                 //     localStorage.setItem('tiktokAuthToken', response.data.newTiktokToken);
    //                 // }
    //                 setIsTiktokLoggedIn(response.data.isTiktokLoggedIn);
    //                 setIsLoading(false);
    //             })
    //             .catch(error => {
    //                 console.error('Error checking login status:', error);
    //                 localStorage.removeItem('googleToken');
    //                 localStorage.removeItem('instagramAuthToken');
    //                 localStorage.removeItem('tiktokAuthToken');
    //                 setIsLoggedIn(false);
    //                 setIsInstaLoggedIn(false);
    //                 setIsTiktokLoggedIn(false);
    //                 setIsLoading(false);
    //             });
    //     } else {
    //         // No tokens are set, so no need to call the API
    //         setIsLoggedIn(false);
    //         setIsInstaLoggedIn(false);
    //         setIsTiktokLoggedIn(false);
    //         setIsLoading(false);
    //     }
    // };


    const checkLoginStatus = () => {
        setIsLoading(true);
        const googleToken = localStorage.getItem('googleToken');
        const instagramToken = localStorage.getItem('instagramAuthToken');
        const tiktokToken = localStorage.getItem('tiktokAuthToken');
      
        // Check if at least one token is set
        if (googleToken || instagramToken || tiktokToken) {
          axios.get(`${apiUrl}/auth/login/status`, {
            params: {
              token: googleToken, // Google token
              insta_token: instagramToken, // Instagram token
              tiktok_token: tiktokToken // TikTok token
            }
          })
            .then(response => {
              if (response?.data?.newGoogleToken) {
                localStorage.setItem('googleToken', response.data.newGoogleToken);
              }
              setIsLoggedIn(response.data.isLoggedIn);
              setIsInstaLoggedIn(response.data.isInstaLoggedIn);
              if (response?.data?.newTiktokToken) {
                localStorage.setItem('tiktokAuthToken', response.data.newTiktokToken);
              }
              setIsTiktokLoggedIn(response.data.isTiktokLoggedIn);
              setIsLoading(false);
            })
            .catch(error => {
              console.error('Error checking login status:', error);
              localStorage.removeItem('googleToken');
              localStorage.removeItem('instagramAuthToken');
              localStorage.removeItem('tiktokAuthToken');
              setIsLoggedIn(false);
              setIsInstaLoggedIn(false);
              setIsTiktokLoggedIn(false);
              setIsLoading(false);
            });
        } else {
          // No tokens are set, so no need to call the API
          setIsLoggedIn(false);
          setIsInstaLoggedIn(false);
          setIsTiktokLoggedIn(false);
          setIsLoading(false);
        }
      };

    useEffect(() => {
        checkLoginStatus();
    }, []);

    useEffect(() => {
        document.body.classList.add('dcs-iframe-only');
        return () => {
            document.body.classList.remove('dcs-iframe-only');
        };
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            const googleToken = localStorage.getItem('googleToken');
            if (googleToken) {
                setCheckboxState(prevState => ({
                    ...prevState,
                    checkbox_yt: true
                }));
            }
        } else {
            setCheckboxState(prevState => ({
                ...prevState,
                checkbox_yt: false
            }));
        }
    }, [isLoggedIn]);

    const validateForm = () => {
        if (videoTitle.length === 0) {
            toast.error("Video Title is required.");
            return false;
        }
        if (videoDescription.length === 0) {
            toast.error("Video Description is required.");
            return false;
        }

        // if (!IsTermsAgreed) {
        //     toast.error("Please agree to abide by YouTube's Terms of Service and Copyright Policies.");
        //     return false;
        // }

        return true;
    };

    const validateTiktokForm = () => {
        if (tiktokvideoTitle.length === 0) {
            toast.error("Video Title is required.");
            return false;
        }
        if (tiktokaccessSpecifier === '') {
            toast.error("Please choose privacy option.");
            return false;
        }

        if (tiktokDisclose && !tiktokYourBrand && !tiktokBrandedContent) {
            toast.error("You need to indicate if your content promotes yourself, a third party, or both.");
            return false;
        }


        // Add additional validations as needed
        return true;
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();

    //     if (!validateForm()) {
    //         return; // Stop the form submission if validation fails
    //     }

    //     setIsUploading(true); // Start upload


    //     const data = {
    //         video_url: video_url,
    //         title: videoTitle,
    //         description: videoDescription,
    //         accessSpecifier: accessSpecifier,
    //         tags: Array.isArray(videoTags) ? videoTags.join(',') : ''
    //     };

    //     axios.post(apiUrl + '/upload', data, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${localStorage.getItem('googleToken')}`
    //         }
    //     })
    //         .then(response => {
    //             // console.log("response from upload", response);
    //             setIsUploading(false);
    //             const videoId = response.data.videoData.id;  // Make sure the response structure matches
    //             const youtubeVideoUrl = `https://www.youtube.com/watch?v=${videoId}`;
    //             // setYoutubeData({
    //             //     videoId: videoId,
    //             //     videoUrl: youtubeVideoUrl,
    //             //     uploadStatus: true
    //             // });

    //             // setCheckboxState(prevData => ({
    //             //     ...prevData,
    //             //     checkbox1: false
    //             // }));

    //             toast.success('Video uploaded successfully!');

    //         })
    //         .catch(error => {
    //             setIsUploading(false)
    //             // setYoutubeData(prevData => ({
    //             //     ...prevData,
    //             //     uploadStatus: false
    //             // }));
    //             toast.error('Upload failed: ' + error.message);

    //         });
    // };



    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return; // Stop the form submission if validation fails
        }

        setIsUploading(true); // Start upload
        let resp = await uploadToYouTube();
        setIsUploading(false);

    };

    const uploadToYouTube = async () => {
        setIsYoutubeUploading(true);
        // console.log("video_url before upload", video_url);
        const data = {
            video_url: video_url,
            title: videoTitle,
            description: videoDescription,
            accessSpecifier: accessSpecifier,
            tags: Array.isArray(videoTags) ? videoTags.join(',') : ''
        };
        // console.log("data before upload.... ", data);
        axios.post(apiUrl + '/upload', data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('googleToken')}`
            }
        })
            .then(response => {
                // console.log("response from upload", response);
                setIsYoutubeUploading(false);
                const videoId = response.data.videoData.id;  // Make sure the response structure matches
                const youtubeVideoUrl = `https://www.youtube.com/watch?v=${videoId}`;
                setYoutubeData({
                    videoId: videoId,
                    videoUrl: youtubeVideoUrl,
                    uploadStatus: true
                });

                setCheckboxState(prevData => ({
                    ...prevData,
                    checkbox_yt: false
                }));
                toast.success('Video uploaded successfully!');
                return true;

            })
            .catch(error => {
                setIsYoutubeUploading(false)
                setYoutubeData(prevData => ({
                    ...prevData,
                    uploadStatus: false
                }));
                toast.error('Upload failed: ' + error.message);
                return true;
            });
    };

    const uploadVideoToInstagram = async () => {
        setIsInstagramUploading(true);
        const data = {
            title: videoTitle,
            description: videoDescription,
            accessSpecifier: accessSpecifier,
            tags: Array.isArray(videoTags) ? videoTags.join(',') : ''
        };

        axios.post(apiUrl + '/upload', data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('googleToken')}`
            }
        })
            .then(response => {
                // console.log("response from upload", response);
                setIsInstagramUploading(false);
                const videoId = response.data.videoData.id;  // Make sure the response structure matches
                const youtubeVideoUrl = `https://www.youtube.com/watch?v=${videoId}`;
                setYoutubeData({
                    videoId: videoId,
                    videoUrl: youtubeVideoUrl,
                    uploadStatus: true
                });

                setCheckboxState(prevData => ({
                    ...prevData,
                    checkbox_yt: false
                }));

                toast.success('Video uploaded successfully!');

            })
            .catch(error => {
                setIsInstagramUploading(false)
                setYoutubeData(prevData => ({
                    ...prevData,
                    uploadStatus: false
                }));
                toast.error('Upload failed: ' + error.message);

            });
        return true;
    };

    const uploadVideoToTiktok = async () => {
        setIsTiktokUploading(true);
        const data = {
            title: videoTitle,
            description: videoDescription,
            accessSpecifier: accessSpecifier,
            tags: Array.isArray(videoTags) ? videoTags.join(',') : ''
        };

        axios.post(apiUrl + '/upload', data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('googleToken')}`
            }
        })
            .then(response => {
                // console.log("response from upload", response);
                setIsTiktokUploading(false);
                const videoId = response.data.videoData.id;  // Make sure the response structure matches
                const youtubeVideoUrl = `https://www.youtube.com/watch?v=${videoId}`;
                setYoutubeData({
                    videoId: videoId,
                    videoUrl: youtubeVideoUrl,
                    uploadStatus: true
                });

                setCheckboxState(prevData => ({
                    ...prevData,
                    checkbox_yt: false
                }));

                toast.success('Video uploaded successfully!');

            })
            .catch(error => {
                setIsTiktokUploading(false)
                setYoutubeData(prevData => ({
                    ...prevData,
                    uploadStatus: false
                }));
                toast.error('Upload failed: ' + error.message);

            });
        return true;
    };

    const handleTitleChange = (event) => {
        if (event.target.value.length <= MAX_TITLE_LENGTH) {
            setVideoTitle(event.target.value);
        }
    };

    const handleInstagramTitleChange = (event) => {
        if (event.target.value.length <= MAX_TITLE_LENGTH) {
            setinstagramVideoTitle(event.target.value);
        }
    };

    const handleAccessSpecifierChange = (event) => {
        setAccessSpecifier(event.target.value);
    };
    const handleTiktokTitleChange = (event) => {
        if (event.target.value.length <= MAX_TITLE_LENGTH) {
            settiktokVideoTitle(event.target.value);
        }
    };
    // const handletiktokAccessSpecifierChange = (event) => {
    //     settiktokAccessSpecifier(event.target.value);
    //     if (event.target.value == 'SELF_ONLY') {
    //         setTiktokBrandedContent(false);
    //     }
    // };

    const handletiktokAccessSpecifierChange = (event) => {
        const selectedValue = event.target.value;
        settiktokAccessSpecifier(selectedValue);

        // Check if the selected value is 'SELF_ONLY'
        if (selectedValue === PRIVACY_OPTIONS.SELF_ONLY) {
            // If 'SELF_ONLY' is selected, ensure 'Branded Content' is unchecked
            setTiktokBrandedContent(false);
        } else if (selectedValue === PRIVACY_OPTIONS.PUBLIC_TO_EVERYONE && tiktokBrandedContent) {
            // If 'PUBLIC_TO_EVERYONE' is selected and 'Branded Content' is checked, uncheck 'Branded Content'
            settiktokAccessSpecifier(false);
        }
    };

    const handleBrandedContentChange = () => {
        setTiktokBrandedContent(!tiktokBrandedContent)
        // If Branded Content is checked, set the access specifier to PUBLIC
        if (!tiktokBrandedContent) {
            settiktokAccessSpecifier(PRIVACY_OPTIONS.PUBLIC_TO_EVERYONE);
        }
    };
    const handleDescriptionChange = (event) => {
        if (event.target.value.length <= MAX_DESCRIPTION_LENGTH) {
            setVideoDescription(event.target.value);
        }
    };

    const handleTagsChange = (newTags) => {
        setVideoTags(newTags);
    };

    const handleInstagramTagsChange = (newTags) => {
        setinstagramVideoTags(newTags);
    };


    const handleAddPlateformPopup = () => {
        setAddPlateformModel(true);
    }


    useEffect(() => {
        const receiveMessage = (event) => {
            if (event.data.type === 'error') {
                toast.error(event.data.message);
            }
            // Validate the origin if needed
            if (event.data.type === 'googleSuccess') {
                localStorage.setItem('googleToken', event.data.googleToken);
                localStorage.setItem('platform', 'google');
                setIsLoggedIn(true);
            }
            if (event.data.type === 'instaSuccess') {
                localStorage.setItem('instagramToken', event.data.insta_token);
                localStorage.setItem('instagramAuthToken', event.data.insta_auth_token);
                localStorage.setItem('platform', 'instagram');
                setIsInstaLoggedIn(true);
            }
            if (event.data.type === 'tiktokSuccess') {
                localStorage.setItem('tiktokToken', event.data.tiktok_token);
                localStorage.setItem('tiktokAuthToken', event.data.tiktok_auth_token);
                localStorage.setItem('platform', 'tiktok');
                setIsTiktokLoggedIn(true);
            }
        };
        window.addEventListener('message', receiveMessage);
        return () => {
            window.removeEventListener('message', receiveMessage);
        };
    }, []);

    const handlePopupClose = () => {
        // Send a message to the parent window to close the iframe
        window.parent.postMessage({ action: 'closeIframe' }, '*');
    };

    return (
        <>

            {/* <TopInfoBar /> */}

            {/* {JSON.stringify(video_url)} */}
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />

            <div className={`completeFormWrapper ${isLoading ? "CheckingStatus" : ""}`}>
                {
                    !isLoggedIn && !isTiktokLoggedIn && !isInstaLoggedIn && (
                        <NoConnectedAccounts 
                        onAddClick={handleAddPlateformPopup} 
                        />
                    )
                }

                {
                    (isLoggedIn || isTiktokLoggedIn || isInstaLoggedIn) && (
                        <ConnectedAccounts
                            isLoggedIn={isLoggedIn}
                            isTiktokLoggedIn={isTiktokLoggedIn}
                            isInstaLoggedIn={isInstaLoggedIn}
                            onAddClick={handleAddPlateformPopup}
                            video_url={video_url}
                            videoTitle={videoTitle}
                        />
                    )
                }


                <Modal className="ss_modal addPlateformModel" show={addPlateformModel} onHide={handlePlateformClose}>
                    <Modal.Header closeButton>
                        <div className="add_plateform_tutrial_header">
                            <h3>Add social accounts</h3>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="plateformListItems">
                            {!isLoggedIn && (
                                <div className="plateformListItem d-none">
                                    <div className='linkWrapper' onClick={() => handleLogin('google')}>
                                        <img src={youtube_icon} alt="Connect" />
                                        <p>YouTube</p>
                                    </div>
                                </div>
                            )}

                            {
                                !isInstaLoggedIn && (
                                    <div className="plateformListItem">
                                        <div className='linkWrapper' onClick={() => handleLogin('instagram')}>
                                            <img src={instagram_icon} alt="Connect" />
                                            <p>Instagram</p>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                !isTiktokLoggedIn && (
                                    <div className="plateformListItem">
                                        <div className='linkWrapper' onClick={() => handleLogin('tiktok')}>
                                            <img src={tiktok_icon} alt="Connect" />
                                            <p>TIKTOK</p>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default AuthSectionMultiSelect;
