import { React, useState, useEffect } from 'react';
import axios from 'axios';
import { instagram_icon, tiktok_icon, youtube_icon } from '../../assets/social_icons/index.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-bootstrap/Modal";
import NoConnectedAccounts from './AccountTemplateParts/NoConnectedAccounts.js'
import ConnectedAccounts from './AccountTemplateParts/ConnectedAccounts.js'
import './SocialShare.css';
const AuthSectionMultiSelectDev = ({ video_url123, videoTitle, videoDescription, ShortsMakerField, onuserInfChange, onChildEvent, onPlateformFormDataChange, requestUserInfo }) => {
    console.log("requestUserInfo on auth page", requestUserInfo);
    const videData = {
        'video_url': video_url123,
        'videoTitle': videoTitle,
        'videoDescription': videoDescription,

    }
    const shortcodeSettings = ShortsMakerField?.shortcodeSettings1;
    var isPostHogEnabled = true;
    const redirectURI = process.env.REACT_APP_REDIRECT_URL;
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log("redirectURI", redirectURI);
    console.log("apiUrl", apiUrl);
    var video_url = '';
    video_url = video_url123;
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isInstaLoggedIn, setIsInstaLoggedIn] = useState(false);
    const [isTiktokLoggedIn, setIsTiktokLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [userInfo, setUserInfo] = useState({
        google: {},
        instagram: {},
        tiktok: {}
    });
    // const [videoTitle, setVideoTitle] = useState('');

    useEffect(() => {
        onuserInfChange(userInfo);
    }, [userInfo])

    const [checkboxState, setCheckboxState] = useState({
        checkbox_yt: false,
        checkbox_insta: false,
        checkbox_tiktok: false
    });

    const [show, setShow] = useState(false);
    const [addPlateformModel, setAddPlateformModel] = useState(false);
    const handlePlateformClose = () => setAddPlateformModel(false);


    const handleDisconnect = (platform) => {
        console.log(`Attempting to disconnect with ${platform}`);
        switch (platform) {
            case 'instagram':
                localStorage.removeItem('instagramToken');
                localStorage.removeItem('instagramAuthToken');
                localStorage.removeItem('platform');
                setIsInstaLoggedIn(false);
                break;
            case 'tiktok':
                localStorage.removeItem('tiktokToken');
                localStorage.removeItem('tiktokAuthToken');
                localStorage.removeItem('platform');
                setIsTiktokLoggedIn(false);
                break;
            case 'google':
                localStorage.removeItem('googleToken');
                setIsLoggedIn(false);
                break;
            default:
                break;
        }
    };

    let popupWindow = null;
    const handleLogin = (service) => {
        setShow(false);
        handlePlateformClose(); // close the popup.
        let serviceUrl = '';
        var redirectURL = encodeURI(redirectURI);
        switch (service) {
            case 'google':
                serviceUrl = '/auth/google/url?customParam=' + redirectURL;
                break;
            case 'instagram':
                serviceUrl = '/auth/instagram/url?customRedirectURL=' + redirectURL;
                break;
            case 'tiktok':
                serviceUrl = '/auth/tiktok/url?customParam=' + redirectURL;
                break;
            default:
                break;
        }

        axios.get(apiUrl + serviceUrl)
            .then(response => {
                const authUrl = response.data.authUrl;
                popupWindow = window.open(
                    authUrl,
                    'targetWindow',
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=600,
                    height=600`
                );

            })
            .catch(error => {
                console.error(`Failed to retrieve login URL for ${service}:`, error);
            });
    };


    const fetchUserInfo = (platform) => {
        const token = localStorage.getItem(`${platform}Token`);
        // console.log("token", token);
        if (!token) return;

        let url;
        if (platform === 'google') {
            const googleToken = localStorage.getItem('googleToken');
            url = `${apiUrl}/auth/userinfo?access_token=${googleToken}`;
        } else if (platform === 'instagram') {
            const instagramToken = localStorage.getItem('instagramToken');
            url = `${apiUrl}/auth/instagram/userinfo?access_token=${instagramToken}`;
        } else if (platform === 'tiktok') {
            url = `${apiUrl}/auth/tiktok/userinfo?access_token=${token}`;
        }

        axios.get(url)
            .then(response => {
                // console.log("p the", userInfo);
                setUserInfo(prevState => ({
                    ...prevState,
                    [platform]: response.data
                }));
            })
            .catch(error => {
                console.error(`Error fetching ${platform} user info:`, error);
            });
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchUserInfo('google');
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isInstaLoggedIn) {
            fetchUserInfo('instagram');
        }
    }, [isInstaLoggedIn]);

    useEffect(() => {
        if (isTiktokLoggedIn) {
            fetchUserInfo('tiktok');
        }
    }, [isTiktokLoggedIn]);

    const checkLoginStatus = () => {
        setIsLoading(true);
        const googleToken = localStorage.getItem('googleToken');
        const instagramToken = localStorage.getItem('instagramAuthToken');
        const tiktokToken = localStorage.getItem('tiktokAuthToken');
        if (googleToken || instagramToken || tiktokToken) {
            axios.get(`${apiUrl}/auth/login/status`, {
                params: {
                    token: googleToken,
                    insta_token: instagramToken,
                    tiktok_token: tiktokToken
                }
            })
                .then(response => {
                    setIsLoggedIn(response.data.isLoggedIn);
                    setIsInstaLoggedIn(response.data.isInstaLoggedIn);
                    if (response?.data?.newTiktokToken) {
                        localStorage.setItem('tiktokAuthToken', response.data.newTiktokToken);
                    }
                    setIsTiktokLoggedIn(response.data.isTiktokLoggedIn);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error checking login status:', error);
                    localStorage.removeItem('googleToken');
                    localStorage.removeItem('instagramAuthToken');
                    localStorage.removeItem('tiktokAuthToken');
                    setIsLoggedIn(false);
                    setIsInstaLoggedIn(false);
                    setIsTiktokLoggedIn(false);
                    setIsLoading(false);
                });
        } else {
            setIsLoggedIn(false);
            setIsInstaLoggedIn(false);
            setIsTiktokLoggedIn(false);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            const googleToken = localStorage.getItem('googleToken');
            if (googleToken) {
                setCheckboxState(prevState => ({
                    ...prevState,
                    checkbox_yt: true
                }));
            }
        } else {
            setCheckboxState(prevState => ({
                ...prevState,
                checkbox_yt: false
            }));
        }
    }, [isLoggedIn]);

    const handleAddPlateformPopup = () => {
        setAddPlateformModel(true);
    }

    useEffect(() => {
        const receiveMessage = (event) => {
            if (event.data.type === 'error') {
                toast.error(event.data.message);
            }
            // Validate the origin if needed
            if (event.data.type === 'googleSuccess') {
                localStorage.setItem('googleToken', event.data.googleToken);
                localStorage.setItem('platform', 'google');
                setIsLoggedIn(true);
            }
            if (event.data.type === 'instaSuccess') {
                localStorage.setItem('instagramToken', event.data.insta_token);
                localStorage.setItem('instagramAuthToken', event.data.insta_auth_token);
                localStorage.setItem('platform', 'instagram');
                setIsInstaLoggedIn(true);
            }
            if (event.data.type === 'tiktokSuccess') {
                localStorage.setItem('tiktokToken', event.data.tiktok_token);
                localStorage.setItem('platform', 'tiktok');
                setIsTiktokLoggedIn(true);
            }
        };
        window.addEventListener('message', receiveMessage);
        return () => {
            window.removeEventListener('message', receiveMessage);
        };
    }, []);

    return (
        <>
            <div className={`completeFormWrapper ${isLoading ? "CheckingStatus" : ""}`}>
                {
                    !isLoggedIn && !isTiktokLoggedIn && !isInstaLoggedIn && (
                        <NoConnectedAccounts
                            onAddClick={handleAddPlateformPopup}
                            isPostHogEnabled={isPostHogEnabled}
                        />
                    )
                }

                {
                    (isLoggedIn || isTiktokLoggedIn || isInstaLoggedIn) && (
                        <ConnectedAccounts
                            isLoggedIn={isLoggedIn}
                            isTiktokLoggedIn={isTiktokLoggedIn}
                            isInstaLoggedIn={isInstaLoggedIn}
                            onAddClick={handleAddPlateformPopup}
                            videData={videData}
                            onHandleDisconnect={handleDisconnect}
                            isPostHogEnabled={isPostHogEnabled}
                            userInfo={userInfo}
                            onChildEvent={onChildEvent}
                            onPlateformFormDataChange={onPlateformFormDataChange}
                            requestUserInfo={requestUserInfo}
                        />
                    )
                }

                <Modal className="ss_modal addPlateformModel onlyForShare" show={addPlateformModel} onHide={handlePlateformClose}>
                    <Modal.Header closeButton>
                        <div className="add_plateform_tutrial_header">
                            <h3>Add social accounts</h3>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="plateformListItems">
                            {!isLoggedIn && (
                                <div className="plateformListItem">
                                    <div className='linkWrapper' onClick={() => handleLogin('google')}>
                                        <img src={youtube_icon} alt="Connect" />
                                        <p>YouTube</p>
                                    </div>
                                </div>
                            )}

                            {
                                !isInstaLoggedIn && (
                                    <div className="plateformListItem">
                                        <div className='linkWrapper' onClick={() => handleLogin('instagram')}>
                                            <img src={instagram_icon} alt="Connect" />
                                            <p>Instagram</p>
                                        </div>
                                    </div>
                                )
                            }

                            {
                                !isTiktokLoggedIn && (
                                    <div className="plateformListItem">
                                        <div className='linkWrapper' onClick={() => handleLogin('tiktok')}>
                                            <img src={tiktok_icon} alt="Connect" />
                                            <p>TIKTOK</p>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </>
    );
}

export default AuthSectionMultiSelectDev;
