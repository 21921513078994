import { useEffect, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import VideoThumbnail from "../VideoThumbnail";
import Error from "../Error";
import Loading from "../Loading";
import { getRequest, getRequestStatus } from "../../api/shorts-api";

let interval;
const PROGRESS = {
  FAILED: {
    value: 0,
    message: "Failed",
    stringValue: "failed",
  },
  CANCELLED: {
    value: 0,
    message: "Cancelled",
    stringValue: "cancelled",
  },
  WAITING_IN_QUEUE: {
    value: 0,
    message: "Waiting in queue...",
    stringValue: "waiting_in_queue",
  },
  FETCHING_VIDEO: {
    value: 10,
    message: "Fetching video...",
    stringValue: "fetching_video",
  },
  TRANSCRIBING_AUDIO: {
    value: 20,
    message: "Analyzing video...",
    stringValue: "transcribing_audio",
  },
  OPEN_AI_SUGGESTIONS: {
    value: 40,
    message: "Finding interesting segments",
    stringValue: "open_ai_suggestions",
  },
  FINALIZING_DATA: {
    value: 90,
    message: "Finalizing Data...",
    stringValue: "finalizing_data",
  },
  TASK_COMPLETE: {
    value: 100,
    message: "Task complete!",
    stringValue: "task_complete",
  },
};

const ProcessingScreen = ({ request, onSuccess, onBack, onBackHome, previousProgress, previousmessage, ShortsMakerField }) => {
  const previousFailed = (previousmessage == 'failed' || previousmessage == 'Failed') ? true : false;
  const [progress, setProgress] = useState(previousProgress);
  const [message, setMessage] = useState(previousmessage);
  const [error, setError] = useState("");
  const [requestId, setRequestId] = useState("");

  const [hasError, setHasError] = useState(previousFailed);
  const [failReason, setFailReason] = useState('');
  const [showSpinner, setShowSpinner] = useState(true);
  useEffect(() => {
    setRequestId(request._id);
  }, [request]);

  useEffect(() => {
    console.log('setRequestId', requestId)

    const fetchStatus = async () => {
      try {
        console.log('requestId', requestId)
        // const fetchedRequest = await getRequest(requestId);
        const fetchedRequest = await getRequestStatus(requestId);
        const stringValue = fetchedRequest.status;
        // let failureReason = fetchedRequest?.failureReason || '';
        setFailReason(fetchedRequest?.failureReason || '')
        console.log("status", stringValue);
        const progressItem = findProgressByStringValue(stringValue);
        setProgress(progressItem.value);
        setMessage(progressItem.message);

        if (
          stringValue === PROGRESS.FAILED.stringValue ||
          stringValue === PROGRESS.TASK_COMPLETE.stringValue
        ) {
          clearInterval(interval);

          if (stringValue === PROGRESS.FAILED.stringValue) {
            // Set the error view
            setHasError(true);
            setShowSpinner(false);
          }

          if (stringValue === PROGRESS.TASK_COMPLETE.stringValue) {

            // Add requestId to the current URL as a parameter
            const url = new URL(window.location);
            url.searchParams.set('requestId', requestId);
            window.history.pushState({}, '', url); // This will add the parameter without reloading the page

            // Scroll to the top of the page
            window.scrollTo(0, 0);

            onSuccess(requestId);

            // let sm_pluginsURL = ShortsMakerField?.shortcodeSettings1?.auth_settings_options?.signin_url_1 + "?pgnInstallId=ShortsMaker&install=true";
            // var channelLinkjson = { "requestId": requestId }
            // var encodedData = encodeURIComponent(
            //   JSON.stringify(channelLinkjson));
            // var updatedUrl = sm_pluginsURL + '&msg=' + encodedData;
            // window.location.href = updatedUrl;

          }
        }
      } catch (error) {
        clearInterval(interval);
      }
    };

    interval = setInterval(fetchStatus, 3000);

    return () => clearInterval(interval);
  }, [requestId, onSuccess]);

  const findProgressByStringValue = (stringValue) => {
    // Iterate over the keys of the PROGRESS object
    for (let key in PROGRESS) {
      if (PROGRESS.hasOwnProperty(key)) {
        // Check if the stringValue matches
        if (PROGRESS[key].stringValue === stringValue) {
          return PROGRESS[key];
        }
      }
    }
    // Return undefined or a suitable default value if no match is found
    return undefined;
  };

  const handleGoBack = () => {
    // Send cancel event to websocket
    // location.reload();
    window.location.href = request.redirectUrl ?? 'http://videopeel.com';
    // onBack();
    
  };

  const parseYouTubeURL = (url) => {
    if (url) {
      const regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      const match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    } else {
      return false;
    }

  };

  return (
    <div className='shortsMakerSmallWrap'>
      <div className="main_wrapperyt">
        <section className="youtube-down-sec tools-sec converting-video-sec">
          <div className="container">
            <div className="tools-heading">
              {error && <Error>{error}</Error>}
              <h1 className="shortMaker-heading">
                {
                  hasError ? "An error has occurred." : "AI is analyzing your video."
                }

              </h1>

            </div>
            <div className={`shortsImageWrapper ${hasError ? 'error' : ''}`}>
              <VideoThumbnail parsedURL={parseYouTubeURL(request?.url)} />
            </div>

            <div className="youtube-down-con shortsMakerProgressWrapper">

              {
                !hasError ? (
                  <>
                    <form>
                      <ProgressBar
                        completed={progress}
                        bgColor="#1679FF"
                        height="6px"
                        isLabelVisible={false}
                        baseBgColor="#ECF3FF"
                      />

                      <div className="percentage-text">
                        {' '}
                        {progress}%
                      </div>
                      <p className='shortsLoadingLabel'>
                        {message}
                      </p>
                    </form>
                    <button
                      className="ss_btn_back"
                      onClick={handleGoBack}
                    >
                      Go to Dashboard
                    </button>
                    <p className="mt-5">
                      You can safely leave this page. We will email you once the process is complete. Typically, this process takes 15-40 minutes.
                    </p>
                  </>
                )
                  :
                  (
                    <>
                      <div className="error-response">
                        <p className="error-msg">An error occurred while creating the video.
                          Please try again.</p>
                        {
                          showSpinner ?
                            (<div className="error-display loadingWrapper"><Loading /></div>)
                            :
                            (<div className="error-display">Error reason : {failReason}</div>)
                        }

                      </div>

                      <button
                        className="ss_btn_back"
                        onClick={handleGoBack}
                      >
                        Go Back
                      </button>
                    </>
                  )
              }




            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ProcessingScreen;
