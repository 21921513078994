const NoConnectedAccounts = ({ onAddClick }) => {
    return (
        <>

            <div className="mt-5 noConnectedAccountBody">
                <p>No social media accounts connected.</p>
                <p>Add one now to get started with the scheduler!</p>
                <button className='btn btn-outline-primary' onClick={onAddClick}><svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 h-4 w-4"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRul="evenodd" clipRule="evenodd"></path></svg> Add Account</button>
            </div>
        </>
    )
}

export default NoConnectedAccounts;