import { useState, useEffect, useRef, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { getRequest } from "../../../api/shorts-api";

const Short = ({ item, requestId, handleShowShortPreview }) => {
  const [localItem, setLocalItem] = useState(item);
  const [isrecompile, setisrecompile] = useState(localItem?.recompiling ?? false);
  const [isrecompileError, setisrecompileError] = useState(localItem?.recompileFailed ?? false);
  const [videoDuration, setVideoDuration] = useState(null);
  const videoRef = useRef(null);

  const handleVideoLoad = () => {
    if (videoRef.current) {
      setVideoDuration(videoRef.current.duration);
    }
  };

  const handleShortClick = () => {
    handleShowShortPreview(localItem?.id, requestId, localItem);
  };

  useEffect(() => {
    let interval;
    const fetchStatus = async () => {
      try {
        const fetchedRequest = await getRequest(requestId);
        const isRecompiling = fetchedRequest?.shorts.some(short => short.id === localItem?.id && short?.recompiling);
        setisrecompile(isRecompiling);
        setisrecompileError(fetchedRequest?.shorts.some(short => short.id === localItem?.id && short?.recompileFailed));

        if (!isRecompiling) {
          clearInterval(interval);
          const updatedItem = fetchedRequest?.shorts.find(short => short.id === localItem?.id);
          if (updatedItem) {
            setLocalItem(updatedItem);
          }
        }
      } catch (error) {
        clearInterval(interval);
      }
    };

    if (isrecompile) {
      interval = setInterval(fetchStatus, 6000);
    }

    return () => clearInterval(interval);
  }, [isrecompile, requestId, localItem?.id]);

  const formatDurationMinToSec = useMemo(() => (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }, []);

  const clips = localItem?.clips;
  const offset_x = clips && clips[0] ? clips[0].offset_x : 0;
  const scaleFactor = 258 / 1920;
  const adjustedOffsetX = offset_x * scaleFactor;
  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: `${adjustedOffsetX}px center`,
  };

  return (
    <div className="col" onClick={handleShortClick}>
      <Card className={`card rounded-5 bg-light border-0`} style={{ cursor: "pointer" }}>
        <div className="SingleShortWrapper">
          <div className="shortWrpper">
            <div className={`short-video-wrapper ${isrecompile || isrecompileError ? "ssDisabled" : ""}`}>
              {isrecompile && <div className='isCompilingLabel'>Compiling</div>}
              {isrecompileError && <div className='isCompilingLabel'>Error</div>}
              <div className='ssContentOnly'>
                <div className="shortClipTime" style={{ textShadow: "1px 1px 2px black", paddingTop: "5px" }}>
                  {formatDurationMinToSec(videoDuration)}
                </div>
                {localItem?.video_url ? (
                  <div className="videoWrap">
                    <video ref={videoRef} key={isrecompile} id="editVideo" controls="" onLoadedMetadata={handleVideoLoad}>
                      <source src={localItem?.video_url} type="video/mp4" />
                    </video>
                  </div>
                ) : (
                  <div className="imageWrap" style={{ width: '145px', height: '258px', overflow: 'hidden' }}>
                    <img src={localItem?.thumbnail} alt={localItem?.title} style={imageStyle} />
                  </div>
                )}
              </div>
            </div>
            <div className="short-detail -wrapper">
              <h3>{localItem?.title}</h3>
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Short;