import React, { useState, useEffect, lazy, Suspense } from 'react';
import Short from './Short';
import BackIcon from '../../assets/back.svg';
import yt_icon_black from '../../assets/yt_icon_black.svg';
import posthog from 'posthog-js';
import { Form, Button } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FeedbackModal from './FeedbackModal';


import languageOptions from './../languageOptions.js';

const ShortsList = ({ userId, userEmail, request, onBackToOne, onSelect, ShortsMakerField }) => {
  console.log("ShortsMakerField on shorts list page", ShortsMakerField);
  var shortsCreatorURL = ShortsMakerField?.shortcodeSettings1?.auth_settings_options?.shortsCreator_endpoints_base;
  console.log("request on shorts list", request);
  const [orderOption, setOrderOption] = useState(
    request?.heatmap && request.heatmap.length > 0 ? 'score' : 'chronological'
  );


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const shortId = urlParams.get('shortId');

    if (shortId) {
      const shortItem = document.getElementById(shortId);
      if (shortItem) {
        shortItem.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const preferLengthMetaData =
    [
      { value: 'all', label: 'All' },
      { value: 'auto', label: 'Auto' },
      {
        value: 'under30sec',
        label: '<30s',
        start: 0,
        end: 29,
      },
      {
        value: 'under60sec',
        label: '30s~60s',
        start: 30,
        end: 59,
      },

      {
        value: 'under90sec',
        label: '60s~90s',
        start: 60,
        end: 89,
      },
      {
        value: 'under3min',
        label: '90s~3m',
        start: 90,
        end: 179,
      },
      {
        value: 'under5min',
        label: '3m~5m',
        start: 180,
        end: 299,
      },
      {
        value: 'under10min',
        label: '5m~10m',
        start: 300,
        end: 599,
      },
    ];
  const [selectedPreferredLength, setSelectedPreferredLength] = useState('all');
  // const [selectedPreferredLength, setSelectedPreferredLength] = useState(
  //   request.preferredLength === 'auto' ? 'all' : request.preferredLength || 'all'
  // );

  const filterShortsByPreferredLength = (shorts, preferredLength) => {
    if (preferredLength === 'all' || preferredLength === 'auto') {
      return shorts;
    }

    const selectedLength = preferLengthMetaData.find(({ value }) => value === preferredLength);
    if (!selectedLength) {
      return shorts;
    }

    const { start, end } = selectedLength;
    return shorts.filter(short => {
      const duration = short.endTimestamp - short.startTimestamp;
      return duration >= start && duration <= end;
    });
  };


  const shortcodeSettings = ShortsMakerField?.shortcodeSettings1;
  const requestId = request?._id;
  const requestUserEmail = request?.userEmail;

  // Check if the user's email contains 'ssemble.com' or 'vlogr.com'
  const isValidDomain = userEmail && (userEmail.includes('vlogr.com'));

  const isCurrentUserRequest = typeof userEmail !== 'undefined' && (userEmail === requestUserEmail);
  const startFormatted = new Date(request?.start * 1000).toISOString().slice(11, 19);
  const endFormatted = new Date(request?.end * 1000).toISOString().slice(11, 19);
  const totalTime = request?.end - request?.start;
  const totalTimeFormated = new Date(totalTime * 1000).toISOString().slice(11, 19);
  const youTubeURL = request?.url;




  function trackPageView(pageName, eventProperties) {
    //amplitude.getInstance().logEvent(pageName, eventProperties);
  }

  function trackPosthogPageView(pageName, eventProperties) {
    posthog.capture(pageName, eventProperties);
  }

  useEffect(() => {
    //console.log("Short Code Settings :"+ JSON.stringify(shortcodeSettings));
    const eventProperties1 = {
      page_title: document.title,
      page_url: window.location.href,
      requestId: requestId,
    };
    

   

  }, []);

  const onBackHandle = () => {
    window.location.href = request?.redirectUrl;
  };

  const handleOrderChange = (option) => {
    setOrderOption(option);
  };

  const [modalShow, setModalShow] = useState(false);

  const handleOnSuccess = (status) => {
    setModalShow(false);
    if (status) {
      toast.success('Thanks for submitting the feedback.');
    } else {
      toast.success('Something went wrong');
    }
  };

  const unsortedGroupedByChapter = request.shorts.reduce((acc, item) => {
    const { chapterTitle } = item;
    const groupKey = chapterTitle || 'No Chapter Title';

    if (!acc[groupKey]) {
      acc[groupKey] = [];
    }

    acc[groupKey].push(item);
    return acc;
  }, {});

  const groupedByChapter = Object.entries(unsortedGroupedByChapter).reduce((acc, [chapterTitle, chapterShorts]) => {
    const sortedChapterShorts = chapterShorts.sort((a, b) => {
      if (orderOption === 'chronological') {
        return a.startTimestamp - b.startTimestamp;
      } else if (orderOption === 'score') {
        return b.viral_score - a.viral_score;
      }
      return 0;
    });

    acc[chapterTitle] = sortedChapterShorts;
    return acc;
  }, {});

  const sortedShorts = request.shorts.sort((a, b) => {
    if (orderOption === 'score') {
      return b.viral_score - a.viral_score;
    }
    return 0;
  });

  const styles = {
    shortsListTopNav: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      // padding: '20px',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      background: 'none',
      border: 'none',
      cursor: 'pointer',
    },
    backIcon: {
      marginRight: '5px',
    },
    logoContainer: {
      // position: 'absolute',
      // left: '50%',
      transform: 'translateX(-50%)',
    },
    headerLogo: {
      height: '30px',
      width: 'auto',
    },
  };


  return (

    <div className='shortsListWrap'>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />
      <div className="shortsListTopNavWrap">
        <div style={styles.shortsListTopNav}>
          <button style={styles.backButton} onClick={onBackHandle}>
            <img src={BackIcon} alt="back icon" style={styles.backIcon} />
            <span className="text-xs text-grey-8">Go Back</span>
          </button>
          
          <div style={styles.logoContainer}>
            <img 
              src="https://cdn.prod.website-files.com/5e85d123c718420965df9442/5ea4f3808ad6b6571695275e_VPlogo-p-800.png" 
              alt="VP Logo" 
              style={styles.headerLogo}
            />
          </div>
          
          {/* This empty div helps maintain the space-between alignment */}
          <div></div>
        </div>
      </div>


      <div className="shortsMakerSmallWrap ShortsListCustomWrapper">
        <div className="languagesLabelWrappers">
          <div className="ChosenLanguageInfoWrap">
            <div className="languageLabel">Video Language</div>
            <div className="flagAndNameWrap">
              <div className="languageFlag">
                {languageOptions.find((option) => option.code === request?.language)?.icon}
              </div>
              <div className="languageName">
                {languageOptions.find((option) => option.code === request?.language)?.label ||
                  'Select Language'}
              </div>
            </div>
          </div>
          {request?.captionLanguage && request?.captionLanguage !== '' && (
            <div className="ChosenLanguageInfoWrap">
              <div className="languageLabel">Caption Language</div>
              <div className="flagAndNameWrap">
                <div className="languageFlag">
                  {languageOptions.find((option) => option.code === request?.captionLanguage)
                    ?.icon}
                </div>
                <div className="languageName">
                  {languageOptions.find((option) => option.code === request?.captionLanguage)
                    ?.label || 'Select Language'}
                </div>
              </div>
            </div>
          )}
        </div>

        <div className='filter_sort_wrapper'>
          {
            request.preferredLength != 'auto' && (


              <div className="prefered_length_filter">
                <label className='custom_label'>
                  Preferred Shorts length
                </label>

                {preferLengthMetaData.map(({ value, label, start, end }) => (
                  (value === request.preferredLength) && (
                    <Form.Check
                      key={value}
                      value={value}
                      type="checkbox"
                      aria-label={`checkbox ${label}`}
                      label={label}
                      name="preferedShortLength"
                      checked={selectedPreferredLength === value}
                      onChange={() => setSelectedPreferredLength(selectedPreferredLength === value ? 'all' : value)}
                      className="customRadioBtns customCheckbox"
                    />
                  )
                ))}
              </div>
            )
          }

          <div className="order-options customTabsForOrder">
            <div className='label_order'>Sort by </div>
            <div
              className={`order-tab ${orderOption === 'chronological' ? 'active' : ''}`}
              onClick={() => handleOrderChange('chronological')}
            >
              Time
            </div>
            <div
              className={`order-tab ${orderOption === 'score' ? 'active' : ''}`}
              onClick={() => handleOrderChange('score')}
            >
              Score
            </div>
          </div>
        </div>


        {/* {orderOption === 'chronological' ? (
          <div>
            {Object.keys(groupedByChapter).map((chapterTitle, index) => {
              const filteredShorts = filterShortsByPreferredLength(
                groupedByChapter[chapterTitle],
                selectedPreferredLength
              );

              return filteredShorts.length > 0 ? (
                <div key={chapterTitle} style={{ marginBottom: '20px' }}>
                  {chapterTitle !== 'No Chapter Title' && (
                    <h3 className="charpterHeading" style={{ fontWeight: 'bold', padding: '10px 0' }}>
                      {`Chapter ${index + 1}: ${chapterTitle}`}
                    </h3>
                  )}
                  <div>
                    {filteredShorts.map((item) => (
                      <div className="short-item" key={item.id}>
                        <div className='d-none startTimeStampLabel'>startTimestamp: {item.startTimestamp}</div>
                        <Short
                          userId={userId}
                          requestUserEmail={requestUserEmail}
                          item={item}
                          index={index}
                          ShortsMakerField={ShortsMakerField}
                          requestId={requestId}
                          isCurrentUserRequest={isCurrentUserRequest}
                          isValidDomain={isValidDomain}
                          youTubeURL={youTubeURL}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null;
            })}
          </div>
        ) : (
          <div>
            {filterShortsByPreferredLength(sortedShorts, selectedPreferredLength).map((item, index) => (
              <div className="short-item" key={item.id}>
                <div className='d-none startTimeStampLabel'>startTimestamp: {item.startTimestamp}</div>
                <Short
                  userId={userId}
                  requestUserEmail={requestUserEmail}
                  item={item}
                  index={index}
                  ShortsMakerField={ShortsMakerField}
                  requestId={requestId}
                  isCurrentUserRequest={isCurrentUserRequest}
                  isValidDomain={isValidDomain}
                  youTubeURL={youTubeURL}
                  shortsCreatorURL={shortsCreatorURL}
                />
              </div>
            ))}
          </div>
        )} */}

        {orderOption === 'chronological' ? (
          <div>
            {Object.keys(groupedByChapter).map((chapterTitle, index) => {
              const filteredShorts = filterShortsByPreferredLength(
                groupedByChapter[chapterTitle],
                selectedPreferredLength
              );

              return filteredShorts.length > 0 ? (
                <div key={chapterTitle} style={{ marginBottom: '20px' }}>
                  {chapterTitle !== 'No Chapter Title' && (
                    <h3 className="charpterHeading" style={{ fontWeight: 'bold', padding: '10px 0' }}>
                      {`Chapter ${index + 1}: ${chapterTitle}`}
                    </h3>
                  )}
                  <div>
                    {filteredShorts.map((item) => {
                      // Create a complete paragraph for each short
                      const paragraph = item.sentenceIndexes.map(index => request.sentences[index].sentence).join(' ');

                      return (
                        <div id={item.id} className="short-item" key={item.id}>
                          <div className='d-none startTimeStampLabel'>startTimestamp: {item.startTimestamp}</div>
                          <Short
                            userId={userId}
                            requestUserEmail={requestUserEmail}
                            item={item}
                            index={index}
                            ShortsMakerField={ShortsMakerField}
                            requestId={requestId}
                            isCurrentUserRequest={isCurrentUserRequest}
                            isValidDomain={isValidDomain}
                            youTubeURL={youTubeURL}
                            paragraph={paragraph} // Pass the paragraph as a prop
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null;
            })}
          </div>
        ) : (
          <div>
            {filterShortsByPreferredLength(sortedShorts, selectedPreferredLength).map((item, index) => {
              // Create a complete paragraph for each short
              const paragraph = item.sentenceIndexes.map(index => request.sentences[index].sentence).join(' ');

              return (
                <div id={item.id} className="short-item" key={item.id}>
                  <div className='d-none startTimeStampLabel'>startTimestamp: {item.startTimestamp}</div>
                  <Short
                    userId={userId}
                    requestUserEmail={requestUserEmail}
                    item={item}
                    index={index}
                    ShortsMakerField={ShortsMakerField}
                    requestId={requestId}
                    isCurrentUserRequest={isCurrentUserRequest}
                    isValidDomain={isValidDomain}
                    youTubeURL={youTubeURL}
                    shortsCreatorURL={shortsCreatorURL}
                    paragraph={paragraph} // Pass the paragraph as a prop
                  />
                </div>
              );
            })}
          </div>
        )}



        {userId !== undefined && isCurrentUserRequest && (
          <div className="requestFeedBackWrapper">
            <p>What do you think about the Shorts clips we generated? I'd love to hear your thoughts.</p>
            <button variant="primary" onClick={() => setModalShow(true)}>
              Give Feedback
            </button>
            {
              modalShow && (
                <FeedbackModal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  onSuccess={handleOnSuccess}
                  requestId={requestId}
                />
              )
            }

          </div>
        )}
      </div>
    </div>
  );
};

export default ShortsList;