import React from "react";
import UserVideo from "./UserVideo";
import { useMemo } from 'react';
import languageOptions from './../languageOptions.js';
const UserVideos = ({ videos, onDeleteRequest, onVideoClick }) => {
  const sortedVideos = useMemo(() => {
    return [...videos].sort((a, b) => new Date(b.date) - new Date(a.date));
  }, [videos]);
  console.log("sortedVideos", sortedVideos)
  const handleDeleteRequest = (id) => {
    onDeleteRequest(id);
  };

  const handleVideoClick = (id) => {
    onVideoClick(id);
  };

  return (
    <div className='draftSection mt-4 pt-5'>
      <div className="row row-cols-1 row-cols-sm-1 row-cols-md-2 g-4">
        {sortedVideos.map((video) => (
          <React.Fragment key={video.id}>
            <input type="hidden" name="requestVideoID" value={video.id} />
            <UserVideo
              {...video}
              onDelete={() => handleDeleteRequest(video.id)}
              onClick={() => handleVideoClick(video.id)}
              langFlag={languageOptions.find((option) =>
                option.code === (video.captionLanguage != null && video.captionLanguage !== '' ? video.captionLanguage : video.language)
              )?.icon}
              langName={video.captionLanguage != null && video.captionLanguage !== '' ? video.captionLanguage : video.language}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default UserVideos;
