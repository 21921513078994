import { useState, useEffect, useRef, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import Score from '../../ShortsList/Score';
const ShortPreviewModal = ({ item, requestId, show, onHide, handleShareShow }) => {
  console.log("requestId on preview modal", requestId);
  item.requestId = requestId;
  const [localItem, setLocalItem] = useState(item);
  const reason = localItem?.reason;
  const viral_score = localItem?.viral_score;
  const [isrecompile, setisrecompile] = useState(localItem?.recompiling ?? false);
  const [isrecompileError, setisrecompileError] = useState(localItem?.recompileFailed ?? false);
  const [videoDuration, setVideoDuration] = useState(null);
  const videoRef = useRef(null);

  const handleVideoLoad = () => {
    if (videoRef.current) {
      setVideoDuration(videoRef.current.duration);
    }
  };

  const handleVideoPlay = () => {
    if (videoRef.current) {
      videoRef.current.webkitEnterFullscreen = null;
      videoRef.current.webkitExitFullscreen = null;
    }
  };

  const formatDurationMinToSec = useMemo(() => (durationInSeconds) => {
    const minutes = Math.floor(durationInSeconds / 60);
    const seconds = Math.floor(durationInSeconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }, []);

  const clips = localItem?.clips;
  const offset_x = clips && clips[0] ? clips[0].offset_x : 0;
  const scaleFactor = 258 / 1920;
  const adjustedOffsetX = offset_x * scaleFactor;
  const imageStyle = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    objectPosition: `${adjustedOffsetX}px center`,
  };

  return (
    <>
      {show && (
        <Modal id="ss_modal_shorts_uploader" show={show} onHide={onHide} centered className='dcs-iframe-only ss_modal_shorts_uploader modal-lg shortsPreviewModal'>
          <Modal.Header closeButton>
            <div className='ssHeaderWraper'>
              <p>Confirm clip to schedule</p>
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="SingleShortWrapper newShortsList">
              <div className='d-none'>{localItem?.id}</div>
              <h3 className='newShortsHeading'>{localItem?.title}</h3>
              <div className="short newShortsRow">
                <div className={`short-left ${isrecompile || isrecompileError ? "ssDisabled" : ""}`} >

                  {
                    isrecompile && (
                      <div className='isCompilingLabel'>Compiling</div>
                    )
                  }
                  {
                    isrecompileError && (
                      <div className='isCompilingLabel'>Error</div>
                    )
                  }
                  <div className='ssContentOnly'>
                    <Score viral_score={viral_score} />

                    <div
                      className="shortClipTime"
                      style={{
                        textShadow: "1px 1px 2px black",
                        paddingTop: "5px",
                      }}
                    >
                      {formatDurationMinToSec(videoDuration)}
                    </div>
                    {
                      localItem?.video_url
                        ?
                        <div className="videoWrap">
                          <video
                            ref={videoRef}
                            key={isrecompile}
                            id="editVideo"
                            controls={isrecompile || isrecompileError ? "" : "controls"}
                            onLoadedMetadata={handleVideoLoad}
                            onPlay={handleVideoPlay}
                            playsInline
                          >
                            <source
                              src={localItem?.video_url}
                              type="video/mp4"
                            />
                          </video>
                        </div>
                        :
                        <div className="imageWrap" style={{ width: '145px', height: '258px', overflow: 'hidden' }}>
                          <img src={localItem?.thumbnail} alt={localItem?.title} style={imageStyle} />
                        </div>
                    }

                  </div>
                </div>
                <div className="short-right">
                  <div className='shortsContentBox'>
                    <p>
                      {reason}
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </Modal.Body>

          <Modal.Footer>
            <button className='btn btn-primary ss-btn-choose' onClick={() => handleShareShow(localItem)}>Select Clip</button>
          </Modal.Footer>


        </Modal>
      )}
    </>



  );



};

export default ShortPreviewModal;