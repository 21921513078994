import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { instagram_icon, tiktok_icon, youtube_icon } from './../../../assets/social_icons';
import YouTubeForm from './YouTubeForm';
import InstagramForm from './InstagramForm';
import TiktokForm from './TiktokForm';
import Accordion from './Accordion';
const ConnectedAccounts = React.memo((
  { isLoggedIn,
    isTiktokLoggedIn,
    isInstaLoggedIn,
    onAddClick,
    videData,
    onHandleDisconnect,
    isPostHogEnabled,
    userInfo,
    onChildEvent,
    onPlateformFormDataChange,
    requestUserInfo
  }) => {
  console.log("videData on connected accounts", videData);

  console.log("isPostHogEnabled", isPostHogEnabled);
  console.log("userInfo on connected accounts:", userInfo);
  const [activeAccounts, setActiveAccounts] = useState([]);

  const [totalFormData, setTotalFormData] = useState({});


  const [activeAccordion, setActiveAccordion] = useState(activeAccounts[0]);

  const handleAccordionToggle = (accordion) => {
    setActiveAccordion(activeAccordion === accordion ? null : accordion);
  };


  useEffect(() => {
    console.log("totalFormData", totalFormData);
    console.log("activeAccounts", activeAccounts);


    // Set the active accordion to the first item in activeAccounts
    if (activeAccounts.length > 0) {
      setActiveAccordion(activeAccounts[0]);
    } else {
      setActiveAccordion(null);
    }

    // Create an object with platform names as keys and their data from totalFormData
    const platformDataObj = {};

    for (const platform of activeAccounts) {
      if (totalFormData[platform]) {
        platformDataObj[platform] = totalFormData[platform];
      }
    }

    console.log("platformDataObj", platformDataObj);
    onPlateformFormDataChange(platformDataObj);
  }, [totalFormData, activeAccounts]);

  // const handleFormDataChange = (formData) => {
  //   setTotalFormData((prevFormData) => ({
  //     //...prevFormData,
  //     [formData.platform]: formData.data,
  //   }));
  //   // Trigger any necessary events or actions in the parent component
  // };

  const handleFormDataChange = (formData) => {
    setTotalFormData((prevFormData) => ({
      ...prevFormData,
      [formData.platform]: formData.data,
    }));

  };




  const platforms = useMemo(() => [
    { id: 'youtube', plateform_slug: 'google', icon: youtube_icon, name: 'YouTube Shorts', loggedIn: isLoggedIn },
    { id: 'tiktok', plateform_slug: 'tiktok', icon: tiktok_icon, name: 'TIKTOK', loggedIn: isTiktokLoggedIn },
    { id: 'instagram', plateform_slug: 'instagram', icon: instagram_icon, name: 'Instagram Reels', loggedIn: isInstaLoggedIn },
  ], [isLoggedIn, isTiktokLoggedIn, isInstaLoggedIn, onHandleDisconnect]);



  const renderPlatformItem = useCallback((platform) => (
    <div
      key={platform.id}
      className={`plateformItem ${activeAccounts.includes(platform.id) ? 'active' : ''}`}
      onClick={() => {
        setActiveAccounts((prevAccounts) => {
          if (prevAccounts.includes(platform.id)) {
            return prevAccounts.filter((account) => account !== platform.id);
          } else {
            return [...prevAccounts, platform.id];
          }
        });
      }}
    >
      <div className='plateformItemMeta' >
        <img src={platform.icon} alt="" />
        <p>{platform.name}</p>
        {/* <p className='userName'>
          {userInfo[platform.plateform_slug]?.name || userInfo[platform.plateform_slug]?.data?.creator_nickname || ''}
        </p> */}
      </div>
      <div className='crossIconWrap d-none'>
        <i className="bi bi-x" onClick={() => onHandleDisconnect(platform.plateform_slug)}></i>
      </div>
    </div>
  ), [activeAccounts, onHandleDisconnect]);

  const renderForms = () => {
    if (activeAccounts.length === 0) {
      return <p className="selectSchText">Select an account to schedule your post.</p>;
    }
    return activeAccounts.map((account) => {
      switch (account) {
        case 'youtube':
          return (
            <Accordion
              key="youtube"
              isActive={activeAccordion === 'youtube'}
              onToggle={() => handleAccordionToggle('youtube')}
            >
              <YouTubeForm
                videData={videData}
                isPostHogEnabled={isPostHogEnabled}
                userInfo={userInfo}
                onChildEvent={onChildEvent}
                onFormDataChange={handleFormDataChange}
                requestUserInfo={requestUserInfo}
                isActive={activeAccordion === 'youtube'}
              />
            </Accordion>
          );
        case 'instagram':
          return (
            <Accordion
              key="instagram"
              isActive={activeAccordion === 'instagram'}
              onToggle={() => handleAccordionToggle('instagram')}
            >
              <InstagramForm
                videData={videData}
                isPostHogEnabled={isPostHogEnabled}
                userInfo={userInfo}
                onChildEvent={onChildEvent}
                onFormDataChange={handleFormDataChange}
                requestUserInfo={requestUserInfo}
                isActive={activeAccordion === 'instagram'}
              />
            </Accordion>
          );
        case 'tiktok':
          return (
            <Accordion
              key="tiktok"
              isActive={activeAccordion === 'tiktok'}
              onToggle={() => handleAccordionToggle('tiktok')}
            >
              <TiktokForm
                videData={videData}
                isPostHogEnabled={isPostHogEnabled}
                userInfo1={userInfo}
                onChildEvent={onChildEvent}
                onFormDataChange={handleFormDataChange}
                requestUserInfo={requestUserInfo}
                isActive={activeAccordion === 'tiktok'}
              />
            </Accordion>
          );
        default:
          return null;
      }
    });
  };

  // const hasConnectedAccounts = isLoggedIn && isTiktokLoggedIn && isInstaLoggedIn;
  const hasConnectedAccounts = isLoggedIn && isTiktokLoggedIn;

  return (
    <>
      <div className='connectedAccountBody'>
        <div className="connectedPlateforms-container">
          <div className="connectedPlateform connectedPlateforms-container-left">
            <div className="allPlateformText">
              <div className='ss_svg_icon'>
                <svg width="16" height="16" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M14 20.4V14.6C14 14.2686 14.2686 14 14.6 14H20.4C20.7314 14 21 14.2686 21 14.6V20.4C21 20.7314 20.7314 21 20.4 21H14.6C14.2686 21 14 20.7314 14 20.4Z" stroke="currentColor"></path><path d="M3 20.4V14.6C3 14.2686 3.26863 14 3.6 14H9.4C9.73137 14 10 14.2686 10 14.6V20.4C10 20.7314 9.73137 21 9.4 21H3.6C3.26863 21 3 20.7314 3 20.4Z" stroke="currentColor"></path><path d="M14 9.4V3.6C14 3.26863 14.2686 3 14.6 3H20.4C20.7314 3 21 3.26863 21 3.6V9.4C21 9.73137 20.7314 10 20.4 10H14.6C14.2686 10 14 9.73137 14 9.4Z" stroke="currentColor"></path><path d="M3 9.4V3.6C3 3.26863 3.26863 3 3.6 3H9.4C9.73137 3 10 3.26863 10 3.6V9.4C10 9.73137 9.73137 10 9.4 10H3.6C3.26863 10 3 9.73137 3 9.4Z" stroke="currentColor"></path></svg>
              </div>
              <div className="ss_simpleText">All Platforms</div>
              <div className='ss_svg_icon'>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2 h-4 w-4 shrink-0 opacity-50 absolute right-2"><path d="M4.93179 5.43179C4.75605 5.60753 4.75605 5.89245 4.93179 6.06819C5.10753 6.24392 5.39245 6.24392 5.56819 6.06819L7.49999 4.13638L9.43179 6.06819C9.60753 6.24392 9.89245 6.24392 10.0682 6.06819C10.2439 5.89245 10.2439 5.60753 10.0682 5.43179L7.81819 3.18179C7.73379 3.0974 7.61933 3.04999 7.49999 3.04999C7.38064 3.04999 7.26618 3.0974 7.18179 3.18179L4.93179 5.43179ZM10.0682 9.56819C10.2439 9.39245 10.2439 9.10753 10.0682 8.93179C9.89245 8.75606 9.60753 8.75606 9.43179 8.93179L7.49999 10.8636L5.56819 8.93179C5.39245 8.75606 5.10753 8.75606 4.93179 8.93179C4.75605 9.10753 4.75605 9.39245 4.93179 9.56819L7.18179 11.8182C7.35753 11.9939 7.64245 11.9939 7.81819 11.8182L10.0682 9.56819Z" fill="currentColor" fillRul="evenodd" clipRule="evenodd"></path></svg>
              </div>
            </div>

            <div className="allplateformsWrapper">
              {platforms.map((platform) => platform.loggedIn && renderPlatformItem(platform))}
            </div>
            {!hasConnectedAccounts && (
              <div className="addPlateformWrapper">
                <button className="btn btn-outline-primary" onClick={onAddClick}>
                  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-2 h-4 w-4"><path d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z" fill="currentColor" fillRul="evenodd" clipRule="evenodd"></path></svg> Add Account
                </button>
              </div>
            )}
          </div>
          <div className="connectedPlateform connectedPlateforms-container-right">
            {renderForms()}
          </div>
        </div>
      </div>
    </>
  );
});

export default ConnectedAccounts;