const VideoThumbnail = ({ parsedURL }) => {
  return parsedURL ? (
    <img
      className='yt-video-thumbnail rounded-lg'
      data-ytid={parsedURL}
      src={`https://img.youtube.com/vi/${parsedURL}/sddefault.jpg`}
      alt=""
    />
  ) : null;
};

export default VideoThumbnail;
