import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
const YouTubeForm = ({ videData, isPostHogEnabled, userInfo, onChildEvent, onFormDataChange, requestUserInfo, isActive }) => {
  console.log("requestUserInfo on youtube", requestUserInfo);
  const video_url = videData?.video_url;
  const videoTitleYt = videData?.videoTitle;
  const videoDescriptionYt = videData?.videoDescription;
  const [videoTitle, setVideoTitle] = useState(videoTitleYt);
  const [videoDescription, setVideoDescription] = useState(videoDescriptionYt);
  const [accessSpecifier, setAccessSpecifier] = useState('public');
  const MAX_TITLE_LENGTH = 100;
  const MAX_DESCRIPTION_LENGTH = 5000;

  useEffect(() => {
    onFormDataChange({
      platform: 'youtube',
      data: {
        video_url: video_url,
        title: videoTitle,
        description: videoDescription,
        accessSpecifier: accessSpecifier,
      }
    });
  }, [videoTitle, videoDescription, accessSpecifier]);

  const handleTitleChange = (event) => {
    if (event.target.value.length <= MAX_TITLE_LENGTH) {
      setVideoTitle(event.target.value);
    }
  };

  const handleDescriptionChange = (event) => {
    if (event.target.value.length <= MAX_DESCRIPTION_LENGTH) {
      setVideoDescription(event.target.value);
    }
  };

  const handleAccessSpecifierChange = (event) => {
    setAccessSpecifier(event.target.value);
  };

  return (
    <div className="formWrapper">
      <div className='ss_flex_wrapper'>
        <div className='ss_flex_left'>
          <div className='userProfilePic'>
            <img className='profilePic' src={userInfo?.google?.picture} alt={userInfo?.google?.name} />
            <div className='plateformIcon'>
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="12" fill="#FF0000"></rect><path d="M20.221 7.87825C20.0233 7.1439 19.4443 6.56489 18.7099 6.36718C17.3683 6 12.0019 6 12.0019 6C12.0019 6 6.63543 6 5.29382 6.35306C4.57359 6.55077 3.98045 7.1439 3.78274 7.87825C3.42969 9.21986 3.42969 12.0019 3.42969 12.0019C3.42969 12.0019 3.42969 14.7981 3.78274 16.1256C3.98045 16.86 4.55946 17.439 5.29382 17.6367C6.64955 18.0039 12.0019 18.0039 12.0019 18.0039C12.0019 18.0039 17.3683 18.0039 18.7099 17.6508C19.4443 17.4531 20.0233 16.8741 20.221 16.1397C20.5741 14.7981 20.5741 12.0161 20.5741 12.0161C20.5741 12.0161 20.5882 9.21986 20.221 7.87825Z" fill="white"></path><path d="M10.293 14.5721L14.7556 12.0019L10.293 9.43164V14.5721Z" fill="#FF0000"></path></svg>
            </div>

          </div>
        </div>
        <div className='ss_flex_right'>
          {isActive && (
            <div className='plateformFormInfo'>
              <div
                className="mb-4 plateformFormInfoBtnWrap"
              >
                <button
                  type="button"
                  className='ss_social_info social_youtube'
                >
                  YouTube
                </button>
              </div>

            </div>
          )}


          <div className="inputWrapper">
            <input
              type="text"
              className="textbox text-control"
              placeholder="Enter YouTube Video Title"
              value={videoTitle}
              onChange={handleTitleChange}
            />
            {videoTitle.length === 0 && (
              <p className='ss_error'>Title cannot be empty</p>
            )}
          </div>

          <div className="inputWrapper">
            <textarea
              className="textarea text-control"
              placeholder="Enter YouTube Video Description"
              value={videoDescription}
              onChange={handleDescriptionChange}
            />
          </div>

          <div className="radio-wrappe ss-custom-radio">
            <div>
              <input
                type="radio"
                id="public"
                name="accessSpecifier"
                value="public"
                checked={accessSpecifier === 'public'}
                onChange={handleAccessSpecifierChange}
              />
              <label htmlFor="public">Public</label>
            </div>
            <div>
              <input
                type="radio"
                id="private"
                name="accessSpecifier"
                value="private"
                checked={accessSpecifier === 'private'}
                onChange={handleAccessSpecifierChange}
              />
              <label htmlFor="private">Private</label>
            </div>
            <div>
              <input
                type="radio"
                id="unlisted"
                name="accessSpecifier"
                value="unlisted"
                checked={accessSpecifier === 'unlisted'}
                onChange={handleAccessSpecifierChange}
              />
              <label htmlFor="unlisted">Unlisted</label>
            </div>
          </div>
        </div>
      </div>

    </div >
  );
};

export default YouTubeForm;