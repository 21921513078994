import { React, useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import AuthSectionMultiSelectDev from './../SocialShare/AuthSectionMultiSelect-dev';
import DateTimePickerWithTimezone from './../VideoInput/DateTimePickerWithTimezone_bk';
import { toast } from 'react-toastify';
import posthog from 'posthog-js';
import axios from 'axios';
import moment from 'moment';
const IframeModal = (
  {
    show,
    onHide,
    videoUrl,
    videoTitle,
    videoDescription,
    shortsCreatorURL,
    ShortsMakerField,
    requestUserInfo
  }) => {
  /**
   * Recieve the message and act upon it
   * Close iframe when action is 'closeIframe'
   */
  window.addEventListener('message', (event) => {
    if (event.data.action === 'closeIframe') {
      onHide();
    }
  });

  const apiUrl = 'https://plugin-socialuploader-api.shortscreator.online';

  const [totalFormData, setTotalFormData] = useState({})

  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [userInfo1, setUserInfo] = useState({});

  const userId = requestUserInfo?.userId;
  const userEmail = requestUserInfo?.requestUserEmail;
  const requestId = requestUserInfo?.requestId;
  const shortId = requestUserInfo?.item?.id;

  const onuserInfChange = (userInfo) => {
    setUserInfo(userInfo);
  }

  const handleDateTimeChange = (dateTime) => {
    setSelectedDateTime(dateTime);
  };

  const handleTimezoneChange = (timezone) => {
    setSelectedTimezone(timezone);
  };

  const onPlateformFormDataChange = (totalFormData) => {
    console.log("Callback on the ifrmae model......", totalFormData);
    setTotalFormData(totalFormData);
  }

  useEffect(() => {
    console.log("totalFormData on iframe model....", totalFormData);
  }, [totalFormData])

  const handleSubmit = async (event) => {
    event.preventDefault();
    const toast_message = selectedDateTime && selectedDateTime.isAfter(moment()) ? 'Successfully scheduled' : 'Successfully posted';
    console.log("SUbmit button clicked.....");

    if (totalFormData.hasOwnProperty('youtube')) {
      // Call the function for YouTube submission
      await handleYouTubeSubmit(totalFormData.youtube);
    }

    if (totalFormData.hasOwnProperty('tiktok')) {
      // Call the function for TikTok submission
      await handleTikTokSubmit(totalFormData.tiktok);
    }

    if (totalFormData.hasOwnProperty('instagram')) {
      // Call the function for handleInstagramSubmit submission
      await handleInstagramSubmit(totalFormData.instagram);
    }

    onHide();
    toast.success(toast_message);
  };


  const scheduleUpload = async (
    userInfo1,
    userId,
    userEmail,
    requestId,
    shortId, type, scheduledTime, videoData, timezone, accessToken) => {
    try {
      const reqObj = {
        userInfo1,
        userId,
        userEmail,
        requestId,
        shortId,
        type,
        scheduledTime: moment(scheduledTime).tz(timezone).toISOString(),
        videoData,
        timezone,
        accessToken,
      };
      console.log("reqObj", reqObj);
      const response = await axios.post(apiUrl + '/api/schedule-upload', reqObj);
      console.log('Upload scheduled successfully:', response.data);
    } catch (error) {
      console.error('Error scheduling upload:', error);
    }
  };

  const handleYouTubeSubmit = async (youtubeData) => {
    // Perform actions specific to YouTube submission
    console.log('YouTube data:', youtubeData);
    const video_url = youtubeData?.video_url;
    const videoTitle = youtubeData?.title;
    const videoDescription = youtubeData?.description;
    const accessSpecifier = youtubeData?.accessSpecifier;

    // if (isPostHogEnabled) {
    const eventName = "Publish Button Clicked";
    const eventProperties = {
      page_title: document.title,
      page_url: window.location.href,
      platform: 'youtube',
      url: video_url,
      requestId: requestId,
      shortId: shortId,
    };
    posthog.capture(eventName, eventProperties);
    // }



    const type = 'youtube'; // or 'tiktok'
    const scheduledTime = selectedDateTime || new Date();
    const videoData = {
      video_url: video_url,
      title: videoTitle,
      description: videoDescription,
      accessSpecifier: accessSpecifier,
    };
    const timezone = selectedTimezone || moment.tz.guess();
    const accessToken = localStorage.getItem('googleToken');
    scheduleUpload(
      userInfo1,
      userId,
      userEmail,
      requestId,
      shortId,
      type,
      scheduledTime,
      videoData,
      timezone,
      accessToken
    );


  };

  const handleTikTokSubmit = async (tiktokData) => {
    // Perform actions specific to TikTok submission
    console.log('TikTok data:', tiktokData);
    const video_url = tiktokData?.video_url;
    const tiktokvideoTitle = tiktokData?.title;
    const eventName = "Publish Button Clicked";
    const eventProperties = {
      page_title: document.title,
      page_url: window.location.href,
      platform: 'tiktok',
      url: video_url,
      requestId: requestId,
      shortId: shortId,
    };
    posthog.capture(eventName, eventProperties);

    const type = 'tiktok'; // or 'tiktok'
    const scheduledTime = selectedDateTime || new Date();
    const videoData = {
      video_url: video_url,
      tiktokvideoTitle: tiktokvideoTitle,
    };
    const timezone = selectedTimezone || moment.tz.guess();
    const accessToken = localStorage.getItem('tiktokToken');
    scheduleUpload(
      userInfo1,
      userId,
      userEmail,
      requestId,
      shortId,
      type,
      scheduledTime,
      videoData,
      timezone,
      accessToken
    );

  };

  const handleInstagramSubmit = async (instagramData) => {
    // Perform actions specific to YouTube submission
    console.log('YouTube data:', instagramData);
    const video_url = instagramData?.video_url;
    const videoTitle = instagramData?.title;
    const instagramBusinessAccountId = instagramData?.instagramBusinessAccountId;

    // if (isPostHogEnabled) {
    const eventName = "Publish Button Clicked";
    const eventProperties = {
      page_title: document.title,
      page_url: window.location.href,
      platform: 'instagram',
      url: video_url,
      requestId: requestId,
      shortId: shortId,
    };
    posthog.capture(eventName, eventProperties);
    // }



    const type = 'instagram'; // or 'tiktok'
    const scheduledTime = selectedDateTime || new Date();
    const videoData = {
      video_url: video_url,
      title: videoTitle,
      instagramBusinessAccountId: instagramBusinessAccountId
    };
    const timezone = selectedTimezone || moment.tz.guess();
    const accessToken = localStorage.getItem('instagramToken');
    scheduleUpload(
      userInfo1,
      userId,
      userEmail,
      requestId,
      shortId,
      type,
      scheduledTime,
      videoData,
      timezone,
      accessToken
    );
  };

  const onChildEvent = () => {
    onHide();
  }


  return (
    <Modal id="ss_modal_shorts_uploader" show={show} onHide={onHide} centered className='dcs-iframe-only ss_modal_shorts_uploader modal-lg'>
      <Modal.Header closeButton>
        <div>
          <h3>Schedule post</h3>
          <p>Select the social accounts you would like to post from. You may select multiple accounts from the same social platforms.</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        {show && (
          <AuthSectionMultiSelectDev
            video_url123={videoUrl}
            videoTitle={videoTitle}
            videoDescription={videoDescription}
            ShortsMakerField={ShortsMakerField}
            onuserInfChange={onuserInfChange}
            onChildEvent={onChildEvent}
            onPlateformFormDataChange={onPlateformFormDataChange}
            requestUserInfo={requestUserInfo}
          />
        )}
      </Modal.Body>
      <Modal.Footer className='connectedAccountFooter'>
        <div className='align-items-center d-flex gap-3 justify-content-end mt-4 ss_btn_group'>
          <div className=''>
            <DateTimePickerWithTimezone
              onDateTimeChange={handleDateTimeChange}
              onTimezoneChange={handleTimezoneChange}
              btn_text={selectedDateTime ? selectedDateTime.format('YYYY-MM-DD HH:mm') : 'Schedule'}
            />
          </div>

          <button disabled={Object.keys(totalFormData).length === 0} className='btn btn-group-vertical btn-primary' type='submit' onClick={handleSubmit}>
            {selectedDateTime && selectedDateTime.isAfter(moment()) ? 'Schedule' : 'Post now'}

          </button>
        </div>

      </Modal.Footer>
    </Modal>
  );
};

export default IframeModal;
