import axios from 'axios';

const endPoint = 'https://dev-plugin-shortsmaker-api.ssemble.com';
const creditsEndPoint = 'https://api-dev.ssemble.com';

// const endPoint = 'https://plugin-shortsmaker.ssemble.com';
// const creditsEndPoint = 'https://api.ssemble.com';

console.log("endPoint", endPoint);
console.log("creditsEndPoint", creditsEndPoint);
const getShorts = async (data) => {
  console.log('getShorts', data);
  try {
    const response = await axios.post(endPoint + '/initialize-request', data);
    console.log('initialize-request response', response);
    const requestId = response.data.insertedId;
    console.log('insertedId', requestId);
    return requestId;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}

/** 
* Channel Registration functions
*/



// const validateYouTubeURL = async (data) => {
//   console.log('Youtube URL validation request : ', data);
//   try {
//     const response = await axios.post(endPoint + '/url/info', data);
//     console.log('Youtube URL validation response', response);
//     return response.data;
//   } catch (error) {
//     console.error('Error initializing request:', error);
//     return null;
//   }
// }


const validateYouTubeURL = async (data) => {
  console.log('Youtube URL validation request : ', data);
  
  const youtubeRegex = /(?:https?:\/\/)?(?:www\.|m\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(?:embed\/)?(?:v\/)?(?:shorts\/)?(?:live\/)?(?:\S+)/g;

  if (!youtubeRegex.test(data.url)) {
    console.error('Invalid YouTube URL');
    return {
      error: 'Invalid YouTube URL'
    };
  }

  try {
    const response = await axios.post(endPoint + '/url/info', data);
    console.log('Youtube URL validation response', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}


const registerChannel = async (data) => {
  console.log('register channel : ', data);
  try {
    const response = await axios.post(endPoint + '/register-channel', data);
    // const response = { "channelId": "45feace4-d7a9-4126-94fc-f82cee4274c4" };

    console.log('register-channel response', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}


const updateChannel = async (data) => {
  console.log('register channel : ', data);
  try {
    const response = await axios.post(endPoint + '/update-channel', data);

    console.log('update-channel response', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}

const unregisterchannel = async (data) => {
  console.log('register channel : ', data);
  try {
    const response = await axios.post(endPoint + '/unregister-channel', data);
    console.log('register-channel response', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}
const getchannelforuser = async (userEmail) => {

  try {
    const response = await axios.get(endPoint + `/get-channel-for-user?userEmail=${userEmail}`);
    return response.data
  } catch (error) {
    console.error('Error fetching video processing stage:', error);
  }

}

const getSurveyInfo = async (userEmail) => {

  try {
    const response = await axios.get(endPoint + `/survey/${userEmail}`);
    return response.data
  } catch (error) {
    console.error('Error fetching user Survey Data:', error);
  }

}

const postSurveyInfo = async (data) => {
  console.log('Survey data : ', data);
  try {
    const response = await axios.post(endPoint + '/survey', data);

    console.log('Survey-channel response', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing Survey request:', error);
    return null;
  }
}

const fetchchannelinfo = async (channelURL) => {
  // data should be {channelURL : channelURL}
  console.log('register channel : ', channelURL);
  try {

    const response = await axios.get(endPoint + `/fetch-channel-info?channelURL=${channelURL}`);

    console.log('fetch-channel-info response', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}
const enableautoconversion = async (data) => {
  // data should be {channelId : channelId} channelId = Channel registration id
  console.log('register channel : ', data);
  try {
    const response = await axios.post(endPoint + '/enable-auto-conversion', data);
    console.log('fetch-channel-info response', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}
const disableautoconversion = async (data) => {
  // data should be {channelId : channelId} channelId = Channel registration id
  console.log('register channel : ', data);
  try {
    const response = await axios.post(endPoint + '/disable-auto-conversion', data);
    console.log('fetch-channel-info response', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}
const fetchgeneratedrequests = async (channelId) => {
  console.log('register channel : ', channelId);
  try {
    // const response = await axios.get(endPoint + '/fetch-generated-requests', data);
    const response = await axios.get(endPoint + `/fetch-generated-requests?channelId=${channelId}`);
    console.log('fetch-generated-requests response', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}

/** 
* End of Channel Registration functions
*/


/** 
* Feedback functions
*/
const likeshort = async (data) => {
  console.log('like-short', data);
  /**
   * data should be 
   * {
        "requestId": "65e0884ac35b95273e71622d",
        "shortId": "69022ff0-dd03-49f9-a748-a8c7967def48"
    }
   */
  try {
    const response = await axios.post(endPoint + '/like-short', data);
    console.log('like-short', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}
const unlikeshort = async (data) => {
  console.log('like-short', data);
  /**
   * data should be 
   * {
        "requestId": "65e0884ac35b95273e71622d",
        "shortId": "69022ff0-dd03-49f9-a748-a8c7967def48"
    }
   */
  try {
    const response = await axios.post(endPoint + '/unlike-short', data);
    console.log('like-short', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}

const dislikeshort = async (data) => {
  console.log('like-short', data);
  // data should be requestId, shortId, reason-type, others-detail
  try {
    const response = await axios.post(endPoint + '/dislike-short', data);
    console.log('dislike-short', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}
const dislikerequest = async (data) => {
  console.log('like-short', data);
  // data should be requestId, reason-type, others-detail
  try {
    const response = await axios.post(endPoint + '/dislike-request', data);
    console.log('dislike-request', response);
    return response.data;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}

/** 
* End of Feedback functions
*/


const assignRequest = async (data) => {
  console.log('assignRequest', data);

  try {
    const response = await axios.post(endPoint + '/assign-request', data);
    console.log('assign-request', response);
    return response;
  } catch (error) {
    console.error('Error initializing request:', error);
    return null;
  }
}


const getRequestStatus = async (requestId) => {

  try {
    const response = await axios.get(endPoint + `/request-status?requestId=${requestId}`);
    return response.data
  } catch (error) {
    console.error('Error fetching video processing stage:', error, requestId);
  }
};

const getRequest = async (requestId) => {

  try {
    const response = await axios.get(endPoint + `/get-shorts?requestId=${requestId}`);
    return response.data
  } catch (error) {
    console.error('Error fetching video processing stage:', error, requestId);
  }
};

// const getUserRequests = async (userId) => {
//   console.log('getUserRequests', userId)
//   try {
//     const response = await axios.get(endPoint + `/userRequests/${userId}`);
//     return response.data;
//   } catch (error) {
//     console.error('Error fetching user requests:', error);
//     return [];
//   }
// };

const getUserRequests = async (userId, page = 0, limit = 4) => {
  console.log('getUserRequests', userId, page, limit);
  try {
    const response = await axios.get(endPoint + `/userRequests/${userId}`, {
      params: {
        page: page,
        limit: limit,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user requests:', error);
    return [];
  }
};

const deleteRequest = async (id) => {
  try {
    const response = await axios.delete(endPoint + `/requests/${id}`);
    console.log('Video successfully deleted');
    return response
  } catch (error) {
    console.error('Failed to delete the video:', error);
  }
};

const deleteTemplate = async (templateId) => {
  try {
    const response = await axios.delete(endPoint + '/delete-template', {
      data: { templateId },
    });
    console.log('Template successfully deleted');
    return response;
  } catch (error) {
    console.error('Failed to delete the template:', error);
    throw error;
  }
};

const getUserCredits = async (userId) => {
  console.log("Userid:", userId);
  try {
    const response = await axios.get(creditsEndPoint + `/credits/shorts_maker/user/${userId}`);
    console.log('getUserCredits response', response.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching user credits:', error);
    return null;
  }
};

const getTemplates = async (language) => {
  try {
    const response = await axios.get(endPoint + `/get-templates?language=${language}`);
    return response.data
  } catch (error) {
    console.error('Error fetching templates:', error);
  }
}
const getUserTemplates = async (userEmail, language) => {
  try {
    const response = await axios.get(endPoint + `/get-user-templates?userEmail=${userEmail}&language=${language}`);
    return response.data
  } catch (error) {
    console.error('Error fetching templates:', error);
  }
}

export {
  validateYouTubeURL,
  getShorts,
  registerChannel,
  updateChannel,
  unregisterchannel,
  assignRequest,
  getRequest,
  getRequestStatus,
  getUserRequests,
  deleteRequest,
  getUserCredits,
  fetchchannelinfo,
  enableautoconversion,
  disableautoconversion,
  fetchgeneratedrequests,
  likeshort,
  unlikeshort,
  dislikeshort,
  dislikerequest,
  getchannelforuser,
  getTemplates,
  getUserTemplates,
  deleteTemplate,
  getSurveyInfo,
  postSurveyInfo
};