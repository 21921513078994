import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button, Form, FormGroup, FormCheck, FormControl } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FeedbackForm.css'
import posthog from 'posthog-js';
import { dislikerequest } from '../../api/shorts-api.js';

const FeedbackModal = (
  {
    show,
    onHide,
    onSuccess,
    requestId
  }
) => {


  const textareaRef = useRef(null);

  const resetFeedbackForm = () => {
    setFeedbackOptions({
      captionIssue: false,
      typosInCaption: false,
      topicNotGood: false,
      badCuts: false,
      badCroppingFraming: false,
      lengthIssue: false,
      fewerVideos: false,
      othersCheckbox: false,
      others: '',
    });
  }

  const handleOnHide = () => {
    console.log("Cancel button clicked.");
    // reset all the feedback options
    resetFeedbackForm();
    onHide();
  }

  const feedbackCheckboxes = [
    { name: 'captionIssue', label: 'Caption is in wrong language / out of sync' },
    { name: 'typosInCaption', label: 'Too many typos in caption' },
    { name: 'topicNotGood', label: 'The topic is not good' },
    { name: 'badCuts', label: 'Bad cuts' },
    { name: 'badCroppingFraming', label: 'Bad cropping & framing' },
    { name: 'lengthIssue', label: 'The length is not what I want' },
    { name: 'fewerVideos', label: 'Fewer videos generated than I expected' },
  ];

  const [feedbackOptions, setFeedbackOptions] = useState({
    captionIssue: false,
    typosInCaption: false,
    topicNotGood: false,
    badCuts: false,
    badCroppingFraming: false,
    lengthIssue: false,
    fewerVideos: false,
    othersCheckbox: false,
    others: '',
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    console.log("feedbackOptions", feedbackOptions);
    const isAnyCheckboxChecked = Object.values(feedbackOptions).slice(0, feedbackCheckboxes.length).some(value => value);
    const isOthersValid = !feedbackOptions.othersCheckbox || (feedbackOptions.othersCheckbox && feedbackOptions.others.trim() !== '');
    setIsFormValid((isAnyCheckboxChecked && isOthersValid) || (!isAnyCheckboxChecked && feedbackOptions.othersCheckbox && feedbackOptions.others.trim() !== ''));
  }, [feedbackOptions]);

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFeedbackOptions((prevOptions) => ({
      ...prevOptions,
      [name]: checked,
      ...(name === 'othersCheckbox' && !checked && { others: '' }),
    }));
  };

  const handleTextareaChange = (event) => {
    setFeedbackOptions({
      ...feedbackOptions,
      others: event.target.value,
    });
  };

  const getSelectedFeedbackOptions = () => {
    const selectedOptions = feedbackCheckboxes.filter((checkbox) => feedbackOptions[checkbox.name]).map((checkbox) => checkbox.label);
    const othersText = feedbackOptions.othersCheckbox && feedbackOptions.others.trim() ? feedbackOptions.others : '';

    return {
      reasonType: selectedOptions,
      othersDetail: othersText
    };
  };


  async function handleSubmit() {

    console.log("submit clicked.....");

    let selectedFeedbackOptions = getSelectedFeedbackOptions();
    selectedFeedbackOptions.requestId = requestId;
    console.log("on submit -- selectedFeedbackOptions", selectedFeedbackOptions);

    let response = await dislikerequest(selectedFeedbackOptions);
    console.log("dislikerequest response", response);

    if (response?.success) {
      onSuccess(true);
    } else {
      onSuccess(false);
    }
    // reset all the feedback options
    resetFeedbackForm();
  };

  return (
    <Modal show={show} onHide={handleOnHide} centered className='ss_modal_requestfeedback'>
      <Modal.Header>
        <Modal.Title>Feedback</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className='formCheckboxWrapper_ss'>
          {feedbackCheckboxes.map((checkbox) => (
            <FormGroup key={checkbox.name}>
              <FormCheck
                type="checkbox"
                label={checkbox.label}
                name={checkbox.name}
                id={checkbox.name}
                checked={feedbackOptions[checkbox.name]}
                onChange={handleCheckboxChange}
              />
            </FormGroup>
          ))}
          <FormGroup>
            <FormCheck
              type="checkbox"
              label="Others"
              name="othersCheckbox"
              id="othersCheckbox"
              checked={feedbackOptions.othersCheckbox}
              onChange={handleCheckboxChange}
            />
            {feedbackOptions.othersCheckbox && (
              <FormControl
                as="textarea"
                rows={6}
                // placeholder="Your reason..."
                value={feedbackOptions.others}
                onChange={handleTextareaChange}
              />
            )}
          </FormGroup>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className={`ss_btn_wrapper ${isFormValid ? 'two_buttons' : 'one_button'}`} >
          <Button variant="secondary" onClick={handleOnHide}>
            Cancel
          </Button>
          {isFormValid && (
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!isFormValid}
            >
              Submit
            </Button>
          )}

        </div>

      </Modal.Footer>
    </Modal>
  );
};

export default FeedbackModal;
