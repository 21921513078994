import React from 'react';
import {useState, useEffect, useContext, lazy, Suspense, useCallback } from 'react';
import { getRequest, getRequestStatus } from './api/shorts-api';
import VideoInput from './components/VideoInput';
import ProcessingScreen from './components/ProcessingScreen';
import ShortsList from './components/ShortsList';
import $ from "jquery";
import Modal from "react-bootstrap/Modal";
import Loading from './components/Loading.js';
import { useDispatch } from "react-redux";
import { changeShortsMakerInputField } from './Redux/Actions/ToolActions.js'
import './App.css'
import ShortsMakerFieldContext from './ShortsMakerFieldContext';

import { dcsfindProgressByStringValue } from './utils/helpers.js';
import SsembleFullCalendar from './components/SocialShare/Calendar/SsembleFullCalendar.js';
const ChannelView = lazy(() =>
  import('./components/ChannelView')
);

const ListNotFound = lazy(() =>
  import('./components/ShortsList/ListNotFound.js')
);


// import SsemblePricing from './components/ShortsMaker/ShortsPricing/SsemblePricing';
const SsemblePricing = lazy(() =>
  import('./components/ShortsMaker/ShortsPricing/SsemblePricing')
);

const MemoizedShortsList = React.memo(ShortsList);

const MemoizedSsemblePricing = React.memo(SsemblePricing);

const MemoizedVideoInput = React.memo(VideoInput);

const LoggedInUser = ({ userId, userEmail, ytUrl, requestId, pageNow, isPro, onLoad, onCreate }) => {
  console.log("pageNow", pageNow);
  console.log("User is Pro on LoggedinUser", isPro);
  console.log("--------userId------", userId);
  console.log("--------userEmail------", userEmail);
  const ShortsMakerField = useContext(ShortsMakerFieldContext);
  console.log("ShortsMakerField new UI", ShortsMakerField);
  const [url, setURL] = useState('');
  const [step, setStep] = useState(0);
  const [request, setRequest] = useState({});
  const [channelId, setChannelId] = useState('');
  const [clickedButtonId, setClickedButtonId] = useState('');




  const [show, setShow] = useState(false);
  const [PopupShow, setPopupShow] = useState(false);
  const handleClose = () => setShow(false);
  const handlePopupClose = () => setPopupShow(false);

  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState("Fetching Youtube Video...");
  const [surveyCompleted, setSurveyCompleted] = useState(false);

  const dispatch = useDispatch();

  const [hasRecompiling, sethasRecompiling] = useState(false);
  const [upgradeBtnAction, setUpgradeBtnAction] = useState('');

  const [userCountryCode, setUserCountryCode] = useState('');
  const [IsUserFromRestrictedCountry, setIsUserFromRestrictedCountry] = useState(false);

  const unauthorizedCountries = [
    { name: 'India', code: 'IN' },
    { name: 'Pakistan', code: 'PK' },
    { name: 'Nepal', code: 'NP' },
    { name: 'UAE', code: 'AE' },
    { name: 'Bangladesh', code: 'BD' },
  ];

  const whitelistedEmails = [
    'deepak.anand@visions.net.in',
    'abhimanyu@microcosmworks.com'
  ];

  const getUserCountryCode = async () => {
    try {
      const response = await fetch('https://plugin-socialuploader-api.shortscreator.online/api/user-location');
      const data = await response.json();
      return data.countryCode;
    } catch (error) {
      console.error('Error getting user country code:', error);
      return null;
    }
  };

  const checkUserLocation = async () => {
    try {
      
      if (whitelistedEmails.includes(userEmail)) {
        setIsUserFromRestrictedCountry(false);
        return;
      }

      const countryCode = await getUserCountryCode();
      setUserCountryCode(countryCode);

      if (!countryCode || unauthorizedCountries.some((country) => country.code === countryCode)) {
        setIsUserFromRestrictedCountry(true);
      } else {
        setIsUserFromRestrictedCountry(false);
      }
    } catch (error) {
      console.error('Error checking user location:', error);
      setIsUserFromRestrictedCountry(true); // Set to true if there's an error
    }
  };

  useEffect(() => {
    checkUserLocation();
  }, []);


  useEffect(() => {
    GetPriceData();
    $(document).on("click", ".ss_gradient", function () {
      var clickedButtonId = $(this).attr('id');
      setClickedButtonId(clickedButtonId);
      //console.log('Clicked ID', clickedButtonId);
      setShow(true);
    });


    $(document).on("click", ".ss_gradient1", function () {
      var clickedButtonId = $(this).attr('id');
      setClickedButtonId(clickedButtonId);
      //console.log('Clicked ID', clickedButtonId);
      setShow(true);
    });
  }, []);

  useEffect(() => {

    if (step === 1) {
      const urlParams = new URLSearchParams(window.location.search);
      const requestIdParam = urlParams.get("requestId");
      if (requestIdParam !== undefined && requestIdParam !== null) {
        handleRequestClick(requestIdParam);
      }
    }

    if (step === 7) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }, [step]);

  const GetPriceData = () => {
    dispatch(
      changeShortsMakerInputField({
        priceTableData: ShortsMakerField?.shortcodeSettings1?.priceTableData,
      })
    );
  };


  useEffect(() => {

    if (pageNow == 'calendar') {
      setStep(8);
      return;
    }


    if (requestId && typeof userId === 'undefined') {
      handleRequestClick(requestId);
      return;
    }
    setStep(1);
    // const fetchUserSurveyData = async () => {
    //   try {
    //     const surveyData = await getSurveyInfo(userEmail);
    //     console.log("Survey Data", surveyData);
    //     if (surveyData?.surveyData?._id && requestId) {
    //       handleRequestClick(requestId);
    //     } else if (surveyData?.surveyData?._id && !requestId) {
    //       setStep(1);
    //     } else {
    //       setStep(7);
    //     }
    //     return;
    //   } catch (error) {
    //     console.error("Error fetching user data:", error);
    //   }
    // };
    // fetchUserSurveyData();
  }, [onLoad, requestId]);

  const handleURLChange = (url) => {
    setURL(url);
  };

  const handlePopUpOpen = (value) => {
    setUpgradeBtnAction(value);
  };

  const showProcessingScreen = async (requestId) => {
    console.log('showProcessingScreen', requestId)
    // const request = await getRequest(requestId);
    const request = await getRequestStatus(requestId);
    const videoStatus = request.status;
    setRequest(request);

    const progressItem = dcsfindProgressByStringValue(videoStatus);
    setProgress(progressItem.value);
    setMessage(progressItem.message);

    // Add requestId to the current URL as a parameter
    const url = new URL(window.location);
    url.searchParams.set('requestId', requestId);
    window.history.pushState({}, '', url); // This will add the parameter without reloading the page


    setStep(2);
  };


  const showChannelProcessingScreen = async (channelRequestId) => {
    setChannelId(channelRequestId);
    setStep(5); // for showing channel info and button to disconnect
  };


  const handleBack = () => {
    if (step > 2) {
      setStep(step - 1);
    }
  };

  const handleBackToOne = () => {
      let signInRedirectURL = 'https://www.videopeel.com';
      window.location.href = signInRedirectURL;
  };

  const handleSuccess = async (requestId) => {
    const requestDataFinal = await getRequest(requestId);
    setRequest(requestDataFinal);
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleShortsSelect = useCallback(async (request, shortId) => {
    console.log('handleShortsSelect', request);
  }, []);

  const handleRequestClick = async (requestId) => {
    console.log('Video clicked:', requestId);
    setStep(0);
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    try {

      // const request = await getRequest(requestId);
      const request = await getRequestStatus(requestId);
      if (request === undefined) {
        setStep(6);
        return;
      }
      console.log("After request status", request);

      const videoStatus = request.status;
      setRequest(request);

      const progressItem = dcsfindProgressByStringValue(videoStatus);
      setProgress(progressItem.value);
      setMessage(progressItem.message);

      // Add requestId to the current URL as a parameter
      const url = new URL(window.location);
      url.searchParams.set('requestId', requestId);
      window.history.pushState({}, '', url); // This will add the parameter without reloading the page
      if (videoStatus === 'task_complete') {
        const requestDataFinal = await getRequest(requestId);
        setRequest(requestDataFinal);

        setStep(3);
      } else {
        setStep(2);
      }
    } catch (error) {
      console.error('Error fetching video status:', error);
    }
  };

  const renderStep = () => {
    console.log('renderStep ');
    switch (step) {
      case 0:
        return (
          <Loading />
        );

      case 1:
        return (
          // <>
          //   <MemoizedVideoInput
          //     userId={userId}
          //     userEmail={userEmail}
          //     ytUrl={ytUrl}
          //     onURLChange={handleURLChange}
          //     onNextStep={showProcessingScreen}
          //     onClickVideo={handleRequestClick}
          //     ShortsMakerField={ShortsMakerField}
          //     isPro={isPro}
          //     onPopUpOpen={handlePopUpOpen}
          //     IsUserFromRestrictedCountry={IsUserFromRestrictedCountry}
          //     userCountryCode={userCountryCode}
          //   // messengerService={messengerService} // Passing down the messengerService
          //   />
          // </>
          <Loading />
        );
      case 2:
        return (
          <ProcessingScreen
            request={request}
            onSuccess={handleSuccess}
            onBack={handleBack}
            onBackHome={handleBackToOne}
            previousProgress={progress}
            previousmessage={message}
            ShortsMakerField={ShortsMakerField}
          />
        );
      case 3:
        return (
          <Suspense fallback={<Loading />}>
            <MemoizedShortsList
              userId={userId}
              userEmail={userEmail}
              request={request}
              onSelect={handleShortsSelect}
              onBackToOne={handleBackToOne}
              ShortsMakerField={ShortsMakerField}
              hasRecompiling={hasRecompiling}
            />
          </Suspense>
        );
      case 4:
        return (
          <></>
        );

      case 5:
        return (
          <ChannelView
            userId={userId}
            userEmail={userEmail}
            ytUrl={ytUrl}
            onURLChange={handleURLChange}
            onNextStep={showChannelProcessingScreen}
            onClickVideo={handleRequestClick}
            ShortsMakerField={ShortsMakerField}

          />
        );

      case 6:
        return (
          <ListNotFound
            ShortsMakerField={ShortsMakerField}
          />
        );

      case 7:
        return (
          // <SurveyForm
          //   userEmail={userEmail}
          //   ShortsMakerField={ShortsMakerField}
          //   handleSkip={handleBackToOne}
          //   isPro={isPro}
          // />
          <></>
        );

      case 8:
        return (
          <SsembleFullCalendar
            userId={userId}
            userEmail={userEmail}
            ShortsMakerField={ShortsMakerField}
          />
        );

      default:
        return (
          <MemoizedVideoInput
            userId={userId}
            userEmail={userEmail}
            ytUrl={ytUrl}
            onURLChange={handleURLChange}
            onNextStep={showProcessingScreen}
            onClickVideo={handleRequestClick}
            isPro={isPro}
            onPopUpOpen={handlePopUpOpen}
          // messengerService={messengerService} // Passing down the messengerService

          />
        );
    }
  };

  return (
    <div className="loginGenerateShortsWrapper">
      <div className="loginGeneratorShortsContainer">
        <div className="loginGeneratorShortsInner">
          <div className="formShortcode">
            {/* {
              (step == 1 || step === 5) && (
                <NavTabs step={step} setStep={setStep} />
              )
            } */}
            <div className="shortsFormWrappr">
              {renderStep()}
            </div>
          </div>
        </div>
      </div>

      {ShortsMakerField?.UserStatusData &&
        !ShortsMakerField?.UserStatusData?.isProUser && show && (
          <>
            <Modal className="ss_modal" show={show} onHide={handleClose}>
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <div className="ModalWrapper">
                  <div className="leftContent">
                    <div className="shortsClipsWrapper">
                      <img
                        src="https://staging.ssemble.com/wp-content/uploads/2023/11/ProIcon-150x150.png"
                        alt=""
                      />
                      <h4 className="shortsClipHeading">
                        Create more Shorts Clips
                      </h4>
                      <p className="shortsClipContent">
                        Get more upload minutes to create more Shorts Clips.
                      </p>
                    </div>
                  </div>
                  <div className="rightContent">
                    <MemoizedSsemblePricing ShortsMakerField={ShortsMakerField} UpgradeBtnAction={upgradeBtnAction} ClickedButtonId={clickedButtonId} />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}
    </div>
  )
}

export default LoggedInUser
