import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';
import 'moment-timezone';
import 'react-datetime/css/react-datetime.css';
import './DateTimePickerWithTimezone.css';
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";

const DateTimePickerWithTimezone = React.memo(({ onDateTimeChange, onTimezoneChange, btn_text }) => {
  const [selectedDateTime, setSelectedDateTime] = useState(moment());
  const [selectedTimezone, setSelectedTimezone] = useState(moment.tz.guess());
  const [searchTerm, setSearchTerm] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const calendarRef = useRef(null);

  const handleDropdownSelect = useCallback((eventKey, event) => {
    setSelectedTimezone(eventKey);
  }, []);

  useEffect(() => {
    if(isCalendarOpen){
      setSelectedDateTime(moment());
      setSelectedTimezone(moment.tz.guess());
    }
  },[isCalendarOpen]);

  const filteredOptions = useMemo(() =>
    moment.tz.names().filter((timezone) =>
      timezone.toLowerCase().startsWith(searchTerm.toLowerCase())
    ),
    [searchTerm]
  );

  useEffect(() => {
    // const currentDate = moment();
    // if (selectedDateTime.isBefore(currentDate)) {
    //   setSelectedDateTime(currentDate);
    // }
    console.log("selectedDateTime------",selectedDateTime);
  }, [selectedDateTime]);

  const handleDateChange = useCallback((date) => {
    setSelectedDateTime(moment(date));
  }, []);

  const handleHourChange = useCallback((event) => {
    const newHour = parseInt(event.target.value, 10);
    const isPM = selectedDateTime.format('A') === 'PM';
    const adjustedHour = isPM && newHour !== 12 ? newHour + 12 : (newHour === 12 && !isPM ? 0 : newHour);
    const newDateTime = selectedDateTime.clone().hour(adjustedHour);
    if (isValidDateTime(newDateTime)) {
      setSelectedDateTime(newDateTime);
    } else {
      const validDateTime = newDateTime.clone().add(1, 'hour');
      setSelectedDateTime(validDateTime);
    }
  }, [selectedDateTime]);

  const handleMinuteChange = useCallback((event) => {
    const newMinute = parseInt(event.target.value, 10);
    const newDateTime = selectedDateTime.clone().minute(newMinute);
    if (isValidDateTime(newDateTime)) {
      setSelectedDateTime(newDateTime);
    }
  }, [selectedDateTime]);

  const handleMeridiemChange = useCallback((event) => {
    const newMeridiem = event.target.value;
    const currentHour = selectedDateTime.hour();
    let newHour;
    if (newMeridiem === 'AM') {
      newHour = currentHour >= 12 ? currentHour - 12 : currentHour;
    } else {
      newHour = currentHour < 12 ? currentHour + 12 : currentHour;
    }
    const newDateTime = selectedDateTime.clone().hour(newHour);
    if (isValidDateTime(newDateTime)) {
      setSelectedDateTime(newDateTime);
    } else {
      const validDateTime = newDateTime.clone().add(1, 'hour');
      setSelectedDateTime(validDateTime);
    }
  }, [selectedDateTime]);

  const handleCalendarOpen = useCallback(() => {
    setIsCalendarOpen(true);
  }, []);

  const handleCalendarClose = useCallback((closedByClickOutside) => {
    if (typeof closedByClickOutside === 'undefined') {
      setIsCalendarOpen(false);
    } else if (closedByClickOutside) {
      setIsCalendarOpen(false);
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setIsCalendarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDateSelect = () => {
    onDateTimeChange(selectedDateTime);
    onTimezoneChange(selectedTimezone);
    setIsCalendarOpen(false);
  };

  const handleCancelSelect = () => {
    setIsCalendarOpen(false);
  };

  const renderDateTimePicker = useCallback((props, openCalendar, closeCalendar) => (
    <div className="OpenDatePicker">
      <button className='btn btn-group-vertical btn-secondary calenderBtn d-block' onClick={openCalendar}><i className="bi bi-calendar3"></i> {btn_text}</button>
    </div>
  ), [btn_text]);

  const isValidDate = useCallback((currentDate, selectedDate) => {
    const today = moment().startOf('day');
    return currentDate.isSameOrAfter(today, 'day');
  }, []);

  const isValidDateTime = useCallback((dateTime) => {
    const now = moment();

    if (dateTime.isBefore(now)) {
      return false;
    }

    return true;
  }, []);

  const renderDateTimePickerPopup = useMemo(() => (props) => (
    isCalendarOpen && (
      <React.Fragment>
        <div className='calenderWrapperNew' ref={calendarRef}>
          <Datetime
            {...props}
            value={selectedDateTime}
            onChange={handleDateChange}
            timeFormat={false}
            open={isCalendarOpen}
            onClose={handleCalendarClose}
            isValidDate={isValidDate}
          />
          <button className="close-icon" onClick={handleCalendarClose}>
            &times;
          </button>
          <div className="time-picker">
            <select value={selectedDateTime.hour() % 12 || 12} onChange={handleHourChange}>
              {Array.from({ length: 12 }, (_, i) => {
                const hour = i === 0 ? 12 : i;
                const adjustedHour = selectedDateTime.format('A') === 'PM' ? hour + 12 : hour;
                return (
                  <option key={i} value={hour} disabled={!isValidDateTime(selectedDateTime.clone().hour(adjustedHour))}>
                    {hour < 10 ? `0${hour}` : hour}
                  </option>
                );
              })}
            </select>
            <select key={selectedDateTime.minute()} value={selectedDateTime.minute()} onChange={handleMinuteChange}>
              {Array.from({ length: 60 }, (_, i) => (
                <option key={i} value={i} disabled={!isValidDateTime(selectedDateTime.clone().minute(i))}>
                  {i < 10 ? `0${i}` : i}
                </option>
              ))}
            </select>
            <select value={selectedDateTime.hour() >= 12 ? 'PM' : 'AM'} onChange={handleMeridiemChange}>
              <option value="AM" disabled={!isValidDateTime(selectedDateTime.clone().hour(selectedDateTime.hour() % 12))}>AM</option>
              <option value="PM" disabled={!isValidDateTime(selectedDateTime.clone().hour((selectedDateTime.hour() % 12) + 12))}>PM</option>
            </select>
          </div>
          <div className="timezone-picker">
            <ButtonGroup>
              <Button variant="warning">{moment.tz(selectedTimezone).format('z')} {moment.tz(selectedTimezone).format('Z')}</Button>
              <Dropdown as={ButtonGroup} onSelect={handleDropdownSelect} data-bs-display="static">
                <Dropdown.Toggle split variant="warning" className="custom-split-toggle" />
                <Dropdown.Menu className="custom-dropdown-menu" data-bs-display="static">
                  <input
                    type="text"
                    placeholder="Search..."
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ margin: "8px 10px", width: "calc(100% - 20px)", borderRadius: "5px" }}
                  />
                  {filteredOptions.map((timezone) => (
                    <Dropdown.Item key={timezone} eventKey={timezone} className={`dropdownItemInd ${timezone === selectedTimezone ? "active selected" : ""}`}>
                      <span>{timezone} ({moment.tz(timezone).format('z')} {moment.tz(timezone).format('Z')}) - {moment.tz(timezone).format('h:mm A')}</span>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
          </div>

          <div className='datePickerFooterWrap mt-3'>
            <button className='btn ss-danger' onClick={handleCancelSelect} >Cancel</button>
            <button className='btn ss-primary' onClick={handleDateSelect} >OK</button>
          </div>

        </div>
      </React.Fragment>
    )
  ), [isCalendarOpen, selectedDateTime, selectedTimezone, searchTerm, handleDropdownSelect, handleDateChange, handleHourChange, handleMinuteChange, handleMeridiemChange, handleCalendarClose, filteredOptions]);

  return (
    <div>
      <Datetime
        key={isCalendarOpen}
        value={selectedDateTime}
        onChange={handleDateChange}
        renderInput={renderDateTimePicker}
        renderView={renderDateTimePickerPopup}
        onOpen={handleCalendarOpen}
        open={isCalendarOpen}
        onClose={handleCalendarClose}
      />
    </div>
  );
});

export default DateTimePickerWithTimezone;