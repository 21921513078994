import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavLink } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import logo from './../videopeel_logo.png';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import BackIcon from './../assets/back.svg';
function Header() {
    return (
        <Navbar expand="lg" className="bg-body-tertiary sticky-top">
            <Container>
                <p className='minheightP'></p>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto">
                        <NavLink to="/" className="nav-link" >Home</NavLink>
                        <NavLink to="/privacy" className="nav-link" >Privacy Policy</NavLink>
                        <NavLink to="/terms" className="nav-link" >Term Of Use</NavLink>
                    </Nav>
                </Navbar.Collapse> */}
            </Container>
        </Navbar>
    );
}

export default Header;
