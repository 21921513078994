import { react, useEffect, useState, useMemo, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import languageOptions from '../../languageOptions';
import { getUserRequests } from "../../../api/shorts-api";
import UserRequest from '../../UserVideos/UserRequest';

const RequestListModal = ({ userId, show, onHide, onVideoClick }) => {
    const [page, setPage] = useState(0);
    const [limit] = useState(10);
    const [hasMoreRequests, setHasMoreRequests] = useState(true);
    const [requests, setRequests] = useState([]);
    const [isFetchingRequest, setIsFetchingRequest] = useState(true);

    const sortedRequest = useMemo(() => {
        return [...requests].sort((a, b) => new Date(b.date) - new Date(a.date));
    }, [requests]);

    const fetchUserData = useCallback(async () => {
        try {
            setIsFetchingRequest(true);
            const userRequests = await getUserRequests(userId, page, limit);
            setRequests((prevRequests) => [...prevRequests, ...userRequests]);
            setIsFetchingRequest(false);
            setHasMoreRequests(userRequests.length === limit);
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    }, [userId, page, limit]);

    useEffect(() => {
        if (userId) {
            fetchUserData();
        }
    }, [userId, fetchUserData]);

    const handleDeleteRequest = useCallback((id) => {
        console.log("Delete");
    }, []);

    const handleVideoClick = useCallback(async (id) => {
        onVideoClick(id);
    }, [onVideoClick]);

    return (
        <Modal id="ss_modal_shorts_uploader" show={show} onHide={onHide} centered className='dcs-iframe-only ss_modal_shorts_uploader modal-lg requestModel'>
            <Modal.Header closeButton>
                <div>
                    <h3>Schedule post</h3>
                    <p>Select the social accounts you would like to post from. You may select multiple accounts from the same social platforms.</p>
                </div>
            </Modal.Header>
            <Modal.Body>
                {show && (
                    <div className='draftSection'>
                        <div className="g-4 row row-cols-md-3 row-cols-sm-2">
                            {sortedRequest.map((video) => (
                                <div key={video.id} className={`requestCardWrap ${video?.status !== 'task_complete' ? 'd-none' : ''}`}>
                                    <UserRequest
                                        {...video}
                                        onDelete={() => handleDeleteRequest(video.id)}
                                        onClick={() => handleVideoClick(video.id)}
                                        langFlag={languageOptions.find((option) =>
                                            option.code === (video.captionLanguage || video.language)
                                        )?.icon}
                                        langName={video.captionLanguage || video.language}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default RequestListModal;