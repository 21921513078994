import { useState, useEffect, useRef, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import YouTubeFormPreview from './YouTubeFormPreview';
import TikTokFormPreview from './TikTokFormPreview';
const ShortPastPreviewModal = ({ item, requestId, show, onHide, handleShareShow }) => {
  console.log("item on past preview", item);
  const [localItem, setLocalItem] = useState(item);
  const plateFormName = localItem?.name;



  return (
    <>
      {show && (
        <Modal id="ss_modal_shorts_uploader" show={show} onHide={onHide} centered className='dcs-iframe-only ss_modal_shorts_uploader modal-lg shortsPastPreviewModal'>
          <Modal.Header closeButton>
            <div className='ssHeaderWraper'>
              <p>View past post</p>
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="SingleShortWrapper newPastShortsList">
              <div className='left-preview'>

              </div>
              <div className='right-preview'>
                <div className='contentSharedData'>
                  {
                    plateFormName === 'youtube' ? (
                      <YouTubeFormPreview item={localItem} />
                    ) : (
                      <TikTokFormPreview item={localItem} />
                    )
                  }
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className="align-items-center d-flex gap-3 justify-content-end mt-4 ss_btn_group">
              <div className="">
                <div>
                  <div className="OpenDatePicker">
                    <button disabled className="btn btn-group-vertical btn-secondary calenderBtn d-block">
                      <i className="bi bi-calendar3"></i>
                      {item?.upload?.scheduledTime}
                    </button>
                  </div>
                </div>
              </div>
              <button disabled className="btn btn-group-vertical btn-primary" type="submit">Reschedule</button>
            </div>
          </Modal.Footer>


        </Modal>
      )}
    </>



  );



};

export default ShortPastPreviewModal;