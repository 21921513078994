import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './Header';
const TermsOfServices = () => {
    return (
        <>

            <Header />
            <Container>
                <Row className="justify-content-md-center mt-5">
                    <Col md="auto">
                        <h1 className="text-center">Terms of Services</h1>
                        <div className="mt-4">
                            <h2>Introduction</h2>
                            <p>
                                Welcome to ShortsCreator. Thank you for using the ShortsCreator platform and the products, services and features we make available to you as part of the platform (collectively, the “Services”). </p>
                            <p><b>PLEASE READ ON TO LEARN THE RULES AND RESTRICTIONS THAT GOVERN YOUR USE OF THE SERVICES. IF YOU DO NOT UNDERSTAND THE AGREEMENT, OR DO NOT ACCEPT ANY PART OF IT, THEN YOU MAY NOT USE THE SERVICES.</b>
                            </p>

                            <hr />
                            <h2>Your Use of the Services</h2>
                            <p><strong>Effective Date: January 4, 2024</strong></p>
                            <h3>Content on the Services</h3>
                            <p>The content on the Services includes videos, audio (for example music and other sounds), graphics, photos, text (such as comments and scripts), branding (including trade names, trademarks, Services marks, or logos), interactive features, software, typefaces, metrics, and other materials whether provided by you, ShortsCreator or a third party (collectively, “Content”). </p>

                            <p><b>Content is the responsibility of the person or entity that provides it to the Services. ShortsCreator is under no obligation to host or serve Content.</b> If you see any Content you believe does not comply with this Agreement, including by violating the law, you can report it to us at Email: minimalee3950@gmail.com. </p>

                            <h3>Copyright Compliance Clause</h3>
                            <p >By using this application to upload content to YouTube, you agree to abide by <a href="https://www.youtube.com/static?template=terms" target="_blank">YouTube's Terms of Service</a> and <a href="https://www.youtube.com/intl/ALL_in/howyoutubeworks/policies/copyright/" target="_blank">Copyright Policies</a>. You affirm that you have the legal right to use and share all elements of the content you upload, including but not limited to video footage, music, images, and any other copyrighted material. You understand that uploading content without proper authorization may result in the content being removed and/or legal consequences. It is your responsibility to ensure that your content does not infringe on the rights of others.</p>
                            <p>By using our service, you agree to <b>only submit YouTube links to videos that you own or for which you have obtained the necessary legal rights.</b> You acknowledge that you are solely responsible for ensuring that the content you submit does not infringe on the copyright of others. Any violation of copyright laws may result in the termination of your access to our service and legal action.</p>



                            <hr />

                            <h2>Applicable Terms</h2>
                            <p>Your use of the Services is subject to these terms which may be updated from time to time (together, this “Agreement”). The Agreement includes the provisions in this document as well as those in the Privacy Policy. Your use of or participation in certain Services may also be subject to additional policies, rules, and/or conditions (“Additional Terms”), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms. </p>

                            <h2>Who may use the Services</h2>
                            <h3>Age Requirements</h3>
                            <p>You must be at least 13 years old to use the Services; however, children of all ages may use the Services if enabled by a parent or legal guardian. </p>

                            <p>You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you’ve received your parent’s or guardian’s permission to use the Services and have gotten your parent or guardian to agree to this Agreement on your behalf).</p>

                            <h2>Permission by Parent or Guardian</h2>
                            <p>If you are under 18, you represent that you have your parent or guardian’s permission to use the Services. Please have them read this Agreement with you.</p>

                            <p>If you are a parent or legal guardian of a user under the age of 18, by allowing your child to use the Services, you are subject to the terms of this Agreement and responsible for your child’s activity on the Services.</p>

                            <h2>Businesses</h2>
                            <p>If you are using the Services on behalf of a company or organization, you represent that you have authority to act on behalf of that entity, and that such entity accepts this Agreement. You may not make your access credentials or account available to others outside your organization, and you are responsible for all activities that occur using your credentials. </p>

                            <h2>Your Information</h2>
                            <p>Our Privacy Policy explains how we treat your personal data and protect your privacy when you use the Services. </p>

                            <h2>Permissions and Restrictions</h2>
                            <p>You may access and use the Services as made available to you, as long as you comply with this Agreement and applicable law.</p>

                            <p>You represent, warrant, and agree that you will not provide or contribute anything, including any Content Submission (as those terms are defined below), to the Services, or otherwise use or interact with the Services, in a manner that:</p>

                            <ol>
                                <li>infringes or violates the intellectual property rights or any other rights of anyone else (including ShortsCreator);</li>
                                <li>violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by ShortsCreator;</li>
                                <li>is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</li>
                                <li>use output from the Services to develop models that compete with ShortsCreator;</li>
                            </ol>

                            <h2>Information We Collect</h2>
                            <ul>
                                <li><strong>User-Provided Information</strong>: This includes information you provide when you register for an account, such as your name, email address, and username.</li>
                                <li><strong>Automatically Collected Information</strong>: When you use our Services, we may automatically collect information such as your IP address, device information, and usage statistics.</li>
                                <li><strong>Content Information</strong>: Information related to the content you create, upload, or share on the ShortsCreator platform.</li>
                            </ul>




                            <h2>Use of Information</h2>
                            <ul>
                                <li>To provide and improve our Services.</li>
                                <li>To communicate with you about your account or transactions with us.</li>
                                <li>To send you offers and promotions for our products and services.</li>
                            </ul>

                            <h2>Sharing of Information</h2>
                            <p>We may share your information with third-party service providers who perform services on our behalf, such as hosting, data analysis, and payment processing. We require these service providers to maintain the confidentiality and security of your information.</p>

                            <h2>Data Security</h2>
                            <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized or unlawful processing, accidental loss, destruction, or damage.</p>

                            <h2>Children’s Privacy</h2>
                            <p>Our Services are not directed to children under the age of 13. We do not knowingly collect personal information from children under 13.</p>

                            <h2>Changes to this Policy</h2>
                            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>

                            <h2>Contact Us</h2>
                            <p>If you have any questions or concerns about our Privacy Policy, please contact us at:</p>
                            <ul>
                                <li>Email: <a href="mailto:minimalee3950@gmail.com">minimalee3950@gmail.com</a></li>
                            </ul>


                        </div>
                    </Col>
                </Row>
            </Container>


            <Container>
                <Row className="justify-content-md-center p-5">
                    <Col md="auto">
                        <div className="custom-text">© Copyright 2024. All Rights Reserved. </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};
export default TermsOfServices;
