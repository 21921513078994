import * as Flags from 'country-flag-icons/react/3x2';
const languageOptions = [
  { "label": "English", "code": "en", "icon": <Flags.US /> },
  { "label": "Latin", "code": "la", "icon": <Flags.VA /> },
  // { "label": "Korean", "code": "ko", "icon": <Flags.KR /> },
  { "label": "Afrikaans", "code": "af", "icon": <Flags.ZA /> },
  { "label": "Albanian", "code": "sq", "icon": <Flags.AL /> },
  { "label": "Catalan", "code": "ca", "icon": <Flags.ES /> },
  { "label": "Croatian", "code": "hr", "icon": <Flags.HR /> },
  { "label": "Czech", "code": "cs", "icon": <Flags.CZ /> },
  { "label": "Danish", "code": "da", "icon": <Flags.DK /> },
  { "label": "Dutch", "code": "nl", "icon": <Flags.NL /> },
  { "label": "Estonian", "code": "et", "icon": <Flags.EE /> },
  { "label": "Faroese", "code": "fo", "icon": <Flags.FO /> },
  { "label": "Finnish", "code": "fi", "icon": <Flags.FI /> },
  { "label": "French", "code": "fr", "icon": <Flags.FR /> },
  { "label": "Galician", "code": "gl", "icon": <Flags.ES /> },
  { "label": "German", "code": "de", "icon": <Flags.DE /> },
  { "label": "Greek", "code": "el", "icon": <Flags.GR /> },
  { "label": "Hungarian", "code": "hu", "icon": <Flags.HU /> },
  // { "label": "Hindi", "code": "hi", "icon": <Flags.IN /> },
  { "label": "Icelandic", "code": "is", "icon": <Flags.IS /> },
  { "label": "Italian", "code": "it", "icon": <Flags.IT /> },
  { "label": "Latvian", "code": "lv", "icon": <Flags.LV /> },
  { "label": "Lithuanian", "code": "lt", "icon": <Flags.LT /> },
  { "label": "Luxembourgish", "code": "lb", "icon": <Flags.LU /> },
  { "label": "Macedonian", "code": "mk", "icon": <Flags.MK /> },
  { "label": "Maltese", "code": "mt", "icon": <Flags.MT /> },
  { "label": "Norwegian", "code": "no", "icon": <Flags.NO /> },
  { "label": "Polish", "code": "pl", "icon": <Flags.PL /> },
  { "label": "Portuguese", "code": "pt", "icon": <Flags.PT /> },
  { "label": "Romanian", "code": "ro", "icon": <Flags.RO /> },
  { "label": "Russian", "code": "ru", "icon": <Flags.RU /> },
  { "label": "Serbian", "code": "sr", "icon": <Flags.RS /> },
  { "label": "Slovak", "code": "sk", "icon": <Flags.SK /> },
  { "label": "Slovenian", "code": "sl", "icon": <Flags.SI /> },
  { "label": "Spanish", "code": "es", "icon": <Flags.ES /> },
  { "label": "Swedish", "code": "sv", "icon": <Flags.SE /> },
  { "label": "Ukrainian", "code": "uk", "icon": <Flags.UA /> }
].sort((a, b) => {
  if (a.label === "English") return -1;
  if (b.label === "English") return 1;
  return a.label.localeCompare(b.label);
});

export default languageOptions;