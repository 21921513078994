// CustomButton.js
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
const CustomButton = ({ title, icon, text, classListitem, children, onClick }) => {
    const Link = ({ id, children, title }) => (
        <OverlayTrigger placement="bottom" overlay={<Tooltip style={{ fontSize: '14px' }} id={id}>{title}</Tooltip>}>
            {children}
        </OverlayTrigger>
    );
    return (
        <Link title={title} id="t-1">
        <button onClick={onClick} className={classListitem}>
            <img src={icon} /> {text}
        </button>
        </Link>
    );
};

export default CustomButton;
