import { React, useState, useEffect } from 'react';
import { Form, Button, Card } from 'react-bootstrap';

import axios from 'axios';
import { BrowserRouter as Router, Route, Switch, Routes, Link } from "react-router-dom";
import connect_with_yt from './../../connect_with_yt.png';
import connect_with_insta from './../../assets/facebook-icon-reverse.svg';
import connect_with_tiktok from './../../connect_with_tiktok.png';
import info_icon from './../../assets/info_icon.svg';
import PlaceholderImage from './../../assets/placeholder_image.jpg';
import Accordion from 'react-bootstrap/Accordion';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay-ts';
import Modal from "react-bootstrap/Modal";
import InstagramTutorial from './../ShortsMaker/AuthParts/InstagramTutorial';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import VideoPlayer from '../VideoPlayer';
import { useLocation } from 'react-router-dom';
import { instagram_icon, tiktok_icon, youtube_icon } from './../../assets/social_icons';


const AuthSection = ({ video_url123 }) => {
    const location = useLocation();
    // console.log("video_url from iframe", video_url123);
    var video_url = '';
    if (video_url123 !== null) {
        video_url = video_url123;
    } else {
        const ShortsMakerField = location.state?.ShortsMakerField;
        // console.log("ShortsMakerField data on upload page", ShortsMakerField);
        video_url = ShortsMakerField?.video_url;
    }
    // console.log("Video url on upload page", video_url);
    // const video_url = "https://storage.googleapis.com/klap-renders/edbdeadc-0ce7-4503-9ced-8250d3ad72f1-d1bb538c-8a9e-4ddc-b8ef-d36c9d9ee801.mp4";
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isInstaLoggedIn, setIsInstaLoggedIn] = useState(false);
    const [isTiktokLoggedIn, setIsTiktokLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // const [videoFile, setVideoFile] = useState(null);

    const [isUploading, setIsUploading] = useState(false);
    const [uploadMessage, setUploadMessage] = useState('');
    const [alertShow, setAlertShow] = useState(false);
    const [userInfo, setUserInfo] = useState({
        google: {},
        instagram: {},
        tiktok: {}
    });

    const [videoTitle, setVideoTitle] = useState('');
    const [videoDescription, setVideoDescription] = useState('');
    const [videoTags, setVideoTags] = useState([]);
    const [IsTermsAgreed, setTermsAgreed] = useState(false);

    const [accessSpecifier, setAccessSpecifier] = useState('private');

    // tiktok form metas
    const [tiktokvideoTitle, settiktokVideoTitle] = useState('');
    const [tiktokvideoTags, settiktokVideoTags] = useState([]);
    const [tiktokaccessSpecifier, settiktokAccessSpecifier] = useState('');



    const [tiktokDisclose, setTiktokDisclose] = useState(false);
    const [tiktokYourBrand, setTiktokYourBrand] = useState(false);
    const [tiktokBrandedContent, setTiktokBrandedContent] = useState(false);
    const [publishId, setPublishId] = useState('');


    const [comment, setComment] = useState(false);
    const [duet, setDuet] = useState(false);
    const [stitch, setStitch] = useState(false);

    const [show, setShow] = useState(false);
    const [thankyoushow, setThankyoushow] = useState(false);
    const handleClose = () => setShow(false);
    const handleThankYouClose = () => setThankyoushow(false);
    const handlesetShow = () => setShow(true);
    const apiUrl = process.env.REACT_APP_API_URL;
    const App_Url = process.env.REACT_APP_URL;

    const MAX_VIDEO_DURATION_SEC = 300;
    const [videoUrl, setVideoUrl] = useState(''); // State to store video URL

    const [publishButtonDisabled, setPublishButtonDisabled] = useState(false);
    const [creatorInfo, setCreatorInfo] = useState(null);
    const [canPost, setCanPost] = useState(true);
    const [whyCant, setwhyCant] = useState('');
    const [videoPreview, setVideoPreview] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedPageId, setSelectedPageId] = useState(0);
    const [publishButton, setPublishButton] = useState(false);
    const [instagramBusinessAccountId, setInstagramBusinessAccountId] = useState(0);
    const [noInstaAccountText, setNoInstaAccountText] = useState('');
    const [uploadedMediaUrl, setUploadedMediaUrl] = useState('');

    const handleInstagramAccountChange = (event) => {
        setInstagramBusinessAccountId(event.target.value);
    };

    useEffect(() => {
        console.log("creatorInfo ", creatorInfo);
    }, [creatorInfo]);


    useEffect(() => {
        if (!isTiktokLoggedIn) {
            return;
        }
        // Fetch creator information from the API
        axios
            .get(apiUrl + '/creator_info', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('tiktokToken')}`,
                },
            })
            .then((response) => {
                console.log("response --------------------------------", response, response?.data?.error?.code);
                if (response?.data?.error?.code == 'ok') {
                    setCanPost(true);
                    setwhyCant('');
                } else {
                    setCanPost(false);

                    if (response?.data?.error?.code == 'spam_risk_too_many_posts') {
                        setwhyCant('The daily post capability reached.');
                    } else if (response?.data?.error?.code == 'spam_risk_user_banned_from_posting') {
                        setwhyCant('You are banned from making new posts.');
                    } else if (response?.data?.error?.code == 'reached_active_user_cap') {
                        setwhyCant('The daily quota for active publishing has been exceeded.');
                    } else if (response?.data?.error?.code == 'unaudited_client_can_only_post_to_private_accounts') {
                        setwhyCant('Unaudited clients can only post to a private account.');
                    }


                    // setwhyCant(response?.error?.code);
                    console.log("CanPost", canPost);
                }
                setCreatorInfo(response.data);
                // setCanPost(response.data.can_post);

            })
            .catch((error) => {
                console.error('Error fetching creator info:', error);
            });
    }, [isTiktokLoggedIn]);


    useEffect(() => {
        const pollVideoStatus = async () => {
            try {
                const response = await axios.post(`${apiUrl}/upload/status/fetch`, {
                    publish_id: publishId, // Pass the publish_id in the request body
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('tiktokToken')}`,
                    },
                });
                const status = response.data.status;
                if (status === 'PUBLISH_COMPLETE') {
                    // Video upload successful
                    toast.success('Video uploaded successfully!');
                    setUploadMessage('The video has been successfully uploaded to TikTok.');
                    setAlertShow(true);
                    setIsSubmitting(false);
                } else if (status === 'PUBLISH_FAILED') {
                    // Video upload failed
                    toast.error('Video upload failed.');
                    setUploadMessage('The video upload to TikTok has failed.');
                    setAlertShow(true);
                    setIsSubmitting(false);
                } else {
                    // Video upload still in progress, continue polling
                    setTimeout(pollVideoStatus, 5000); // Poll every 5 seconds
                }
            } catch (error) {
                console.error('Error polling video status:', error);
                setIsSubmitting(false);
            }
        };

        if (publishId && setIsSubmitting) {
            pollVideoStatus();
        }
    }, [publishId, setIsSubmitting]);


    useEffect(() => {
        // Disable publish button if Disclose video content is checked and neither Your Brand nor Branded Content is selected
        if (tiktokDisclose && !tiktokYourBrand && !tiktokBrandedContent) {
            setPublishButtonDisabled(true);
        } else {
            setPublishButtonDisabled(false);
        }
    }, [tiktokYourBrand, tiktokBrandedContent, tiktokDisclose]);


    const checkVideoDuration = async (videoUrl, maxDuration) => {
        // Create a video element
        const videoElement = document.createElement('video');

        // Set the source of the video element
        videoElement.src = videoUrl;

        // Ensure that the metadata is loaded
        await new Promise((resolve, reject) => {
            videoElement.addEventListener('loadedmetadata', () => {
                // Once metadata is loaded, resolve the promise
                resolve();
            });

            // Handle errors if the video fails to load
            videoElement.addEventListener('error', (error) => {
                reject(error);
            });
        });

        // Access the duration of the video
        const videoDuration = videoElement.duration;
        // Check if the video duration is within the maximum allowed duration
        return videoDuration <= maxDuration;
    };


    // Define your privacy level options as constants
    const PRIVACY_OPTIONS = {
        SELF_ONLY: 'SELF_ONLY',
        PUBLIC_TO_EVERYONE: 'PUBLIC_TO_EVERYONE',
        MUTUAL_FOLLOW_FRIENDS: 'MUTUAL_FOLLOW_FRIENDS',
    };

    // Function to check if an option should be disabled
    const isOptionDisabled = (option) => {
        return (
            !userInfo?.tiktok?.data?.privacy_level_options ||
            !userInfo?.tiktok?.data?.privacy_level_options.includes(option)
        );
    };


    const handleDisconnect = (platform) => {
        // // console.log(`Attempting to disconnect with ${platform}`);
        switch (platform) {
            case 'instagram':
                localStorage.removeItem('instagramToken');
                localStorage.removeItem('instagramAuthToken');
                localStorage.removeItem('platform');
                setIsInstaLoggedIn(false);
                break;
            case 'tiktok':
                localStorage.removeItem('tiktokToken');
                localStorage.removeItem('tiktokAuthToken');
                localStorage.removeItem('platform');
                setIsTiktokLoggedIn(false);
                break;
            case 'google':
                localStorage.removeItem('googleToken');
                setIsLoggedIn(false);
                break;
            default:
                break;
        }
    };

    let popupWindow = null;
    // const handleLogin = (service) => {
    //     setShow(false);
    //     let serviceUrl = '';
    //     switch (service) {
    //         case 'google':
    //             serviceUrl = '/auth/google/url';
    //             break;
    //         case 'instagram':
    //             serviceUrl = '/auth/instagram/url';
    //             break;
    //         case 'tiktok':
    //             serviceUrl = '/auth/tiktok/url';
    //             break;
    //         default:
    //             break;
    //     }

    //     axios.get(apiUrl + serviceUrl)
    //         .then(response => {
    //             const authUrl = response.data.authUrl;
    //             popupWindow = window.open(
    //                 authUrl,
    //                 'targetWindow',
    //                 `toolbar=no,
    //                 location=no,
    //                 status=no,
    //                 menubar=no,
    //                 scrollbars=yes,
    //                 resizable=yes,
    //                 width=600,
    //                 height=600`
    //             );

    //         })
    //         .catch(error => {
    //             console.error(`Failed to retrieve login URL for ${service}:`, error);
    //         });
    // };


    const handleLogin = (service) => {
        setShow(false);
        let serviceUrl = '';
        switch (service) {
            case 'google':
                serviceUrl = `/auth/google/url?customParam=${encodeURIComponent(App_Url)}`;
                break;
            case 'instagram':
                serviceUrl = `/auth/instagram/url?customParam=${encodeURIComponent(App_Url)}`;
                break;
            case 'tiktok':
                serviceUrl = `/auth/tiktok/url?customParam=${encodeURIComponent(App_Url)}`;
                break;
            default:
                break;
        }

        axios.get(apiUrl + serviceUrl)
            .then(response => {
                const authUrl = response.data.authUrl;
                popupWindow = window.open(
                    authUrl,
                    'targetWindow',
                    `toolbar=no,
                    location=no,
                    status=no,
                    menubar=no,
                    scrollbars=yes,
                    resizable=yes,
                    width=600,
                    height=600`
                );

            })
            .catch(error => {
                console.error(`Failed to retrieve login URL for ${service}:`, error);
            });
    };


    const fetchUserInfo = (platform) => {
        const token = localStorage.getItem(`${platform}Token`);
        // console.log("token", token);
        if (!token) return;

        let url;
        if (platform === 'google') {
            url = `${apiUrl}/auth/userinfo`;
        } else if (platform === 'instagram') {
            const instagramToken = localStorage.getItem('instagramToken');
            console.log("instagramToken", instagramToken);
            url = `${apiUrl}/auth/instagram/userinfo?access_token=${instagramToken}`;
        } else if (platform === 'tiktok') {
            url = `${apiUrl}/auth/tiktok/userinfo?access_token=${token}`;
        }

        axios.get(url)
            .then(response => {
                // console.log("p the", userInfo);
                setUserInfo(prevState => ({
                    ...prevState,
                    [platform]: response.data
                }));
            })
            .catch(error => {
                console.error(`Error fetching ${platform} user info:`, error);
            });
    };

    useEffect(() => {
        if (isLoggedIn) {
            fetchUserInfo('google');
        }
    }, [isLoggedIn]);

    useEffect(() => {
        if (isInstaLoggedIn) {
            fetchUserInfo('instagram');
        }
    }, [isInstaLoggedIn]);

    useEffect(() => {
        if (isTiktokLoggedIn) {
            fetchUserInfo('tiktok');
        }
    }, [isTiktokLoggedIn]);


    const checkLoginStatus = () => {
        setIsLoading(true);
        const googleToken = localStorage.getItem('googleToken');
        const instagramToken = localStorage.getItem('instagramAuthToken');
        const tiktokToken = localStorage.getItem('tiktokAuthToken');

        // Check if at least one token is set
        if (googleToken || instagramToken || tiktokToken) {
            axios.get(`${apiUrl}/auth/login/status`, {
                params: {
                    token: googleToken, // Google token
                    insta_token: instagramToken, // Instagram token
                    tiktok_token: tiktokToken // TikTok token
                }
            })
                .then(response => {
                    // console.log("Login response-------", response);
                    if (response?.data?.newGoogleToken) {
                        localStorage.setItem('googleToken', response.data.newGoogleToken);
                    }
                    setIsLoggedIn(response.data.isLoggedIn);
                    setIsInstaLoggedIn(response.data.isInstaLoggedIn);
                    // if (response?.data?.newTiktokToken) {
                    //     localStorage.setItem('tiktokAuthToken', response.data.newTiktokToken);
                    // }
                    setIsTiktokLoggedIn(response.data.isTiktokLoggedIn);
                    setIsLoading(false);
                })
                .catch(error => {
                    console.error('Error checking login status:', error);
                    localStorage.removeItem('googleToken');
                    localStorage.removeItem('instagramAuthToken');
                    localStorage.removeItem('tiktokAuthToken');
                    setIsLoggedIn(false);
                    setIsInstaLoggedIn(false);
                    setIsTiktokLoggedIn(false);
                    setIsLoading(false);
                });
        } else {
            // No tokens are set, so no need to call the API
            setIsLoggedIn(false);
            setIsInstaLoggedIn(false);
            setIsTiktokLoggedIn(false);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        checkLoginStatus();
    }, []);

    useEffect(() => {
        document.body.classList.add('dcs-iframe-only');
        return () => {
            document.body.classList.remove('dcs-iframe-only');
        };
    }, []);

    const validateForm = () => {
        if (videoTitle.length === 0) {
            toast.error("Video Title is required.");
            return false;
        }
        if (videoDescription.length === 0) {
            toast.error("Video Description is required.");
            return false;
        }

        if (!IsTermsAgreed) {
            toast.error("Please agree to abide by YouTube's Terms of Service and Copyright Policies.");
            return false;
        }

        return true;
    };

    const validateTiktokForm = () => {
        if (tiktokvideoTitle.length === 0) {
            toast.error("Video Title is required.");
            return false;
        }
        if (tiktokaccessSpecifier === '') {
            toast.error("Please choose privacy option.");
            return false;
        }

        if (tiktokDisclose && !tiktokYourBrand && !tiktokBrandedContent) {
            toast.error("You need to indicate if your content promotes yourself, a third party, or both.");
            return false;
        }


        // Add additional validations as needed
        return true;
    };

    // const handleSubmit = (event) => {
    //     event.preventDefault();

    //     if (!validateForm()) {
    //         return; // Stop the form submission if validation fails
    //     }

    //     setIsUploading(true); // Start upload


    //     const data = {
    //         video_url: video_url,
    //         title: videoTitle,
    //         description: videoDescription,
    //         accessSpecifier: accessSpecifier,
    //         tags: Array.isArray(videoTags) ? videoTags.join(',') : ''
    //     };

    //     axios.post(apiUrl + '/upload', data, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             Authorization: `Bearer ${localStorage.getItem('googleToken')}`
    //         }
    //     })
    //         .then(response => {
    //             // console.log("response from upload", response);
    //             setIsUploading(false);
    //             const videoId = response.data.videoData.id;  // Make sure the response structure matches
    //             const youtubeVideoUrl = `https://www.youtube.com/watch?v=${videoId}`;
    //             // setYoutubeData({
    //             //     videoId: videoId,
    //             //     videoUrl: youtubeVideoUrl,
    //             //     uploadStatus: true
    //             // });

    //             // setCheckboxState(prevData => ({
    //             //     ...prevData,
    //             //     checkbox1: false
    //             // }));

    //             toast.success('Video uploaded successfully!');

    //         })
    //         .catch(error => {
    //             setIsUploading(false)
    //             // setYoutubeData(prevData => ({
    //             //     ...prevData,
    //             //     uploadStatus: false
    //             // }));
    //             toast.error('Upload failed: ' + error.message);

    //         });
    // };



    const handleSubmit = (event) => {
        event.preventDefault();

        if (!validateForm()) {
            return; // Stop the form submission if validation fails
        }

        setIsUploading(true); // Start upload

        const data = {
            video_url: video_url,
            title: videoTitle,
            description: videoDescription,
            accessSpecifier: accessSpecifier,
            tags: Array.isArray(videoTags) ? videoTags.join(',') : ''
        };

        axios.post(apiUrl + '/upload', data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('googleToken')}`
            }
        })
            .then(response => {
                // console.log("response from upload", response);
                setIsUploading(false);
                const videoId = response.data.videoData.id;  // Make sure the response structure matches
                const youtubeVideoUrl = `https://www.youtube.com/watch?v=${videoId}`;
                toast.success('Video uploaded successfully!');
            })
            .catch(error => {
                setIsUploading(false)
                const errorMessage = error.response && error.response.data ? error.response.data : error.message;
                toast.error('Upload failed: ' + errorMessage);
            });
    };

    const handleInstaSubmit = (event) => {
        event.preventDefault();
        console.log("setIsUploading", true);
        setIsUploading(true); // Start upload
        setUploadMessage('');
        setAlertShow(false);
        // Create FormData instance
        // const formData = new FormData();
        const data = {
            video_url: video_url,
            title: videoTitle,
            fb_page_id: selectedPageId,
            insta_page_id: instagramBusinessAccountId
        };

        // Request video upload
        axios.post(apiUrl + '/upload/instagram', data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('instagramToken')}`
            }
        })
            .then(response => {
                setIsUploading(false); // Upload complete
                toast.success('Video uploaded successfully!');
                setUploadMessage('Once the video is generated, we will upload it to Instagram reel with the metadata you submitted');
                setAlertShow(true); // Show
                console.log("Media Upload Response", response);
                setUploadedMediaUrl(response.data.publishResponse);

                setThankyoushow(true);
            })
            .catch(error => {
                setIsUploading(false); // Upload complete
                setUploadMessage('Upload failed: ' + error.message);
            });
    };


    const handleTiktokSubmit = (event) => {
        event.preventDefault();

        setIsUploading(true); // Start upload
        setUploadMessage('');
        setAlertShow(false);
        // Create FormData instance
        const formData = new FormData();

        // Request video upload
        axios.post(apiUrl + '/upload/tiktok', formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('tiktokToken')}`
            }
        })
            .then(response => {
                setIsUploading(false); // Upload complete
                setUploadMessage('Once the video is generated, we will upload it to Tiktok with the metadata you submitted');
                setAlertShow(true); // Show
            })
            .catch(error => {
                setIsUploading(false); // Upload complete
                setUploadMessage('Upload failed: ' + error.message);
            });
    };
    // const handleTiktokFeedsSubmit = async (event) => {
    //     event.preventDefault();

    //     if (!validateTiktokForm()) {
    //       return; // Stop the form submission if validation fails
    //     }

    //     // Check if the video duration exceeds the maximum allowed duration
    //     try {
    //       const isDurationValid = await checkVideoDuration(video_url, creatorInfo.max_video_post_duration_sec);

    //       if (!isDurationValid) {
    //         // Display message to user indicating that video duration exceeds the limit
    //         toast.error(`Video duration exceeds the maximum allowed duration of ${creatorInfo.max_video_post_duration_sec} seconds.`);
    //         return; // Stop current publishing attempt
    //       }

    //       if (!canPost) {
    //         toast.error('Cannot post at the moment. Please try again later.');
    //         return; // Stop current publishing attempt
    //       }

    //       setIsSubmitting(true);
    //       setUploadMessage('');
    //       setAlertShow(false);

    //       const data = {
    //         video_url: video_url,
    //         tiktokvideoTitle: tiktokvideoTitle,
    //         tiktokaccessSpecifier: tiktokaccessSpecifier,
    //         comment: comment,
    //         duet: duet,
    //         stitch: stitch,
    //         tiktokDisclose: tiktokDisclose,
    //         tiktokYourBrand: tiktokYourBrand,
    //         tiktokBrandedContent: tiktokBrandedContent,
    //       };

    //       // Request video upload
    //       axios
    //         .post(apiUrl + '/upload/tiktok_feeds', data, {
    //           headers: {
    //             Authorization: `Bearer ${localStorage.getItem('tiktokToken')}`,
    //           },
    //         })
    //         .then((response) => {
    //           setIsSubmitting(false); // Upload complete
    //           toast.success('Video uploaded successfully!');
    //           setUploadMessage('Once the video is generated, we will upload it to Tiktok with the metadata you submitted');
    //           setAlertShow(true); // Show
    //         })
    //         .catch((error) => {
    //           setIsSubmitting(false); // Upload complete
    //           setUploadMessage('Upload failed: ' + error.message);
    //         });
    //     } catch (error) {
    //       console.error('Error while checking video duration:', error);
    //     }
    //   };




    const handleTiktokFeedsSubmit = async (event) => {
        event.preventDefault();

        if (!validateTiktokForm()) {
            return; // Stop the form submission if validation fails
        }

        // Check if the video duration exceeds the maximum allowed duration
        try {
            const isDurationValid = await checkVideoDuration(video_url, creatorInfo?.data?.max_video_post_duration_sec);

            if (!isDurationValid) {
                // Display message to user indicating that video duration exceeds the limit
                toast.error(`Video duration exceeds the maximum allowed duration of ${creatorInfo?.data?.max_video_post_duration_sec} seconds.`);
                return; // Stop current publishing attempt
            }

            if (!canPost) {
                toast.error(whyCant);
                return; // Stop current publishing attempt
            }


            window.scrollTo(0, 0);

            setIsSubmitting(true);
            setIsUploading(true); // Start upload
            setUploadMessage('');
            setAlertShow(false);

            const data = {
                video_url: video_url,
                tiktokvideoTitle: tiktokvideoTitle,
                tiktokaccessSpecifier: tiktokaccessSpecifier,
                comment: comment,
                duet: duet,
                stitch: stitch,
                tiktokDisclose: tiktokDisclose,
                tiktokYourBrand: tiktokYourBrand,
                tiktokBrandedContent: tiktokBrandedContent,
                brand_content_toggle: tiktokBrandedContent, // Set brand_content_toggle based on tiktokBrandedContent
                brand_organic_toggle: tiktokYourBrand // Set brand_organic_toggle based on tiktokYourBrand
            };

            // Request video upload
            axios.post(apiUrl + '/upload/tiktok_feeds', data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('tiktokToken')}`,
                },
            })
                .then((response) => {
                    // Video upload initiated successfully
                    toast.info('Video upload initiated. Polling for status...');
                    setPublishId(response.data.publish_id); // Store the publish_id
                    setIsUploading(false); // Start upload
                })
                .catch((error) => {
                    setIsSubmitting(false);
                    setIsUploading(false); // Start upload
                    setUploadMessage('Upload failed: ' + error.message);
                    toast.error('Error uploading video: ' + error.message);
                });
        } catch (error) {
            console.error('Error while checking video duration:', error);
            toast.error('Error uploading video: ' + error.message);
        }
    };

    // const handleTiktokFeedsSubmit = (event) => {
    //     event.preventDefault();

    //     if (!validateTiktokForm()) {
    //         return; // Stop the form submission if validation fails
    //     }

    //     setIsUploading(true); // Start upload
    //     setUploadMessage('');
    //     setAlertShow(false);

    //     const data = {
    //         video_url: video_url,
    //         tiktokvideoTitle: tiktokvideoTitle,
    //         tiktokaccessSpecifier: tiktokaccessSpecifier,
    //         comment: comment,
    //         duet: duet,
    //         stitch: stitch,

    //     };
    //     // Request video upload
    //     axios.post(apiUrl + '/upload/tiktok_feeds', data, {
    //         headers: {
    //             Authorization: `Bearer ${localStorage.getItem('tiktokToken')}`
    //         }
    //     })
    //         .then(response => {
    //             setIsUploading(false); // Upload complete
    //             toast.success('Video uploaded successfully!');
    //             setUploadMessage('Once the video is generated, we will upload it to Tiktok with the metadata you submitted');
    //             setAlertShow(true); // Show
    //         })
    //         .catch(error => {
    //             setIsUploading(false); // Upload complete
    //             setUploadMessage('Upload failed: ' + error.message);
    //         });
    // };

    const MAX_TITLE_LENGTH = 100;
    const MAX_DESCRIPTION_LENGTH = 5000;

    const handleTitleChange = (event) => {
        if (event.target.value.length <= MAX_TITLE_LENGTH) {
            setVideoTitle(event.target.value);
        }
    };

    const handleDescriptionChange = (event) => {
        if (event.target.value.length <= MAX_DESCRIPTION_LENGTH) {
            setVideoDescription(event.target.value);
        }
    };

    const handleAccessSpecifierChange = (event) => {
        setAccessSpecifier(event.target.value);
    };

    // tiktok handlers
    const handletiktokTitleChange = (event) => {
        if (event.target.value.length <= MAX_TITLE_LENGTH) {
            settiktokVideoTitle(event.target.value);
        }
    };
    // const handletiktokAccessSpecifierChange = (event) => {
    //     settiktokAccessSpecifier(event.target.value);
    //     if (event.target.value == 'SELF_ONLY') {
    //         setTiktokBrandedContent(false);
    //     }
    // };

    const handletiktokAccessSpecifierChange = (event) => {
        const selectedValue = event.target.value;
        settiktokAccessSpecifier(selectedValue);

        // Check if the selected value is 'SELF_ONLY'
        if (selectedValue === PRIVACY_OPTIONS.SELF_ONLY) {
            // If 'SELF_ONLY' is selected, ensure 'Branded Content' is unchecked
            setTiktokBrandedContent(false);
        } else if (selectedValue === PRIVACY_OPTIONS.PUBLIC_TO_EVERYONE && tiktokBrandedContent) {
            // If 'PUBLIC_TO_EVERYONE' is selected and 'Branded Content' is checked, uncheck 'Branded Content'
            settiktokAccessSpecifier(false);
        }
    };

    const handleBrandedContentChange = () => {
        setTiktokBrandedContent(!tiktokBrandedContent)
        // If Branded Content is checked, set the access specifier to PUBLIC
        if (!tiktokBrandedContent) {
            settiktokAccessSpecifier(PRIVACY_OPTIONS.PUBLIC_TO_EVERYONE);
        }
    };

    useEffect(() => {
        const receiveMessage = (event) => {
            if (event.data.type === 'error') {
                toast.error(event.data.message);
            }
            // Validate the origin if needed
            if (event.data.type === 'googleSuccess') {
                localStorage.setItem('googleToken', event.data.googleToken);
                localStorage.setItem('platform', 'google');
                setIsLoggedIn(true);
            }
            if (event.data.type === 'instaSuccess') {
                localStorage.setItem('instagramToken', event.data.insta_token);
                localStorage.setItem('instagramAuthToken', event.data.insta_auth_token);
                localStorage.setItem('platform', 'instagram');
                setIsInstaLoggedIn(true);
            }
            if (event.data.type === 'tiktokSuccess') {
                localStorage.setItem('tiktokToken', event.data.tiktok_token);
                localStorage.setItem('tiktokAuthToken', event.data.tiktok_auth_token);
                localStorage.setItem('platform', 'tiktok');
                setIsTiktokLoggedIn(true);
            }
        };
        window.addEventListener('message', receiveMessage);
        return () => {
            window.removeEventListener('message', receiveMessage);
        };
    }, []);


    return (
        <>
            <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} />

            <LoadingOverlay
                active={isUploading}
                spinner
                text='Uploading video...'
                styles={{ display: 'contents' }}
            />


            <div className="sc_small_center flex-column justify-content-center vh-100">

                <h1 className='mt-5'>Upload Clip To Social Media</h1>
                <p>Use the social media options listed below to upload the generated clip:</p>
                <div className=''>
                    <Accordion defaultActiveKey="1">
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>
                                <img className='socialIconsSmalll' src={youtube_icon} />
                                Upload to YouTube Shorts
                                {
                                    isLoggedIn && (
                                        <a onClick={() => handleDisconnect('google')} className='disconnectbtn btn btn-lg btn-link'>Disconnect</a>
                                    )
                                }</Accordion.Header>
                            <Accordion.Body>
                                <div className="socialWrapper">
                                    {!isLoading && !isLoggedIn && (
                                        <button
                                            className="ytConnect btn btn-group-vertical btn-outline-secondary"
                                            onClick={
                                                () => handleLogin('google')
                                            }
                                        >
                                            <img src={connect_with_yt} alt='Connect with You Tube' />Connect with You Tube
                                        </button>
                                    )}

                                    {
                                        isLoggedIn && (
                                            <>
                                                <div className='userinfoWrapperWrap'>
                                                    {userInfo?.google && userInfo?.google?.email && (
                                                        <div className='shadow userInfoCircle'>
                                                            <img src={userInfo?.google?.picture} />
                                                            <h3>{userInfo?.google?.name}</h3>
                                                        </div>
                                                    )}

                                                    <div className="shadow videoPreview">
                                                        {/* <video
                                                                id="editVideo"
                                                                controls="controls"
                                                            >
                                                                <source
                                                                    src={video_url}
                                                                    type="video/mp4"
                                                                />
                                                            </video> */}

                                                        <VideoPlayer src={video_url} />
                                                    </div>
                                                </div>

                                                <form className='mt-3'>
                                                    <div className='formWrapper'>
                                                        <div className='inputWrapper'>
                                                            <label className="label">
                                                                Title <p><span>{videoTitle.length}</span>/<span>{MAX_TITLE_LENGTH}</span></p>
                                                            </label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="videoTitle"
                                                                placeholder="Video Title"
                                                                value={videoTitle}
                                                                onChange={handleTitleChange}
                                                            />
                                                        </div>
                                                        <div className='inputWrapper'>
                                                            <label
                                                                className="label"
                                                            >
                                                                Description
                                                                <p>
                                                                    <span>{videoDescription.length}</span>/<span>{MAX_DESCRIPTION_LENGTH}</span>
                                                                </p>
                                                            </label>

                                                            <textarea
                                                                className="form-control"
                                                                id="videoDescription"
                                                                rows="3"
                                                                placeholder="Description"
                                                                value={videoDescription}
                                                                onChange={handleDescriptionChange}
                                                            >
                                                            </textarea>
                                                        </div>

                                                        <div className="inputWrapper select-wrapper">
                                                            <label className="label">Video Privacy Status</label>
                                                            <select
                                                                className="accessSpecifiers"
                                                                value={accessSpecifier}
                                                                onChange={handleAccessSpecifierChange}
                                                            >
                                                                <option value="public">Public</option>
                                                                <option value="private">Private</option>
                                                                <option value="unlisted">Unlisted</option>
                                                            </select>
                                                        </div>

                                                    </div>
                                                    <div className='checkboxInlineWrapper mb-3 mt-4'>
                                                        <div className="form-check form-check-inline checkboxWrapper d-flex">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="termsAgreement"
                                                                onChange={(e) => setTermsAgreed(e.target.checked)}
                                                            />
                                                            <label className="form-check-label" htmlFor="termsAgreement">
                                                                By using this application to upload content to YouTube, you agree to abide by
                                                                <a href="https://www.youtube.com/static?template=terms" target="_blank" rel="noopener noreferrer">
                                                                    &nbsp; YouTube's Terms of Service &nbsp;
                                                                </a>
                                                                and
                                                                <a href="https://www.youtube.com/intl/ALL_in/howyoutubeworks/policies/copyright/" target="_blank" rel="noopener noreferrer">
                                                                    &nbsp; Copyright Policies &nbsp;
                                                                </a>.
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <Button variant="success" type='submit' onClick={handleSubmit}>
                                                        Publish
                                                    </Button>
                                                </form>

                                            </>
                                        )
                                    }


                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <img className='socialIconsSmalll' src={instagram_icon} />
                                Upload to Instagram Reel {
                                    isInstaLoggedIn && (
                                        <a onClick={
                                            () => handleDisconnect('instagram')
                                        }
                                            className='disconnectbtn btn btn-lg btn-link'>
                                            Disconnect
                                        </a>)
                                }
                            </Accordion.Header>
                            <Accordion.Body>
                                {!isLoading && !isInstaLoggedIn && (
                                    <button
                                        className="InstaConnect btn btn-primary d-flex gap-3"
                                        onClick={handlesetShow}
                                    >
                                        <img src={connect_with_insta} alt='Connect with Instagram' />Login with Facebook
                                    </button>
                                )}


                                {
                                    isInstaLoggedIn && (
                                        <div className='row'>
                                            <div className="col-4">
                                                <div className='userinfoWrapperWrap'>

                                                    <div className="shadow videoPreview">
                                                        <video
                                                            id="editVideo"
                                                            controls="controls"
                                                        >
                                                            <source
                                                                src={video_url}
                                                                type="video/mp4"
                                                            />
                                                        </video>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-8">
                                                <form className=''>
                                                    <h4
                                                        style={{
                                                            fontSize: 21,
                                                            fontWeight: "bold",
                                                            borderBottom: "1px solid #ccc",
                                                            paddingBottom: 20
                                                        }}
                                                    >
                                                        Upload Short to Instagram Reel
                                                    </h4>
                                                    <p>
                                                        Enter a captivating caption for your video short, then select the appropriate Facebook page and its associated Instagram account where you'd like to share this engaging content with your audience.
                                                    </p>

                                                    <Form.Group className="mb-3" controlId="ssForm.ControlInput1">
                                                        <Form.Label>Caption</Form.Label>
                                                        <Form.Control type="text" placeholder="Write a Caption and add Hashtags"
                                                            value={videoTitle} onChange={handleTitleChange}
                                                        />
                                                    </Form.Group>
                                                   

                                                    {userInfo.instagram.data != null && (
                                                        <Form.Group className="mb-3" controlId="ssForm.ControlTextarea1">
                                                            <Form.Label>Select Instagram Account</Form.Label>
                                                            <div className="row">
                                                                {userInfo.instagram.data.map((item, index) => (
                                                                    <div className="col-6">
                                                                    <Card
                                                                        key={index}
                                                                        className={`m-2 ${instagramBusinessAccountId === item.id ? 'border-primary' : ''}`}
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => setInstagramBusinessAccountId(item.id)}
                                                                    >
                                                                        {item.profile_picture_url ? (
                                                                            <Card.Img variant="top" src={item.profile_picture_url} />
                                                                        ) : (
                                                                            <Card.Img variant="top" src={PlaceholderImage} />
                                                                        )}
                                                                        <Card.Body>
                                                                            <Form.Check
                                                                                type="radio"
                                                                                id={`instagram-account-${item.id}`}
                                                                                label={`@${item.username} ${item.name ? `(${item.name})` : ''}`}
                                                                                name="instagram_account_id"
                                                                                value={item.id}
                                                                                checked={instagramBusinessAccountId === item.id}
                                                                                onChange={handleInstagramAccountChange}
                                                                                disabled={item.disabled}
                                                                            />
                                                                        </Card.Body>
                                                                    </Card>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </Form.Group>
                                                    )}

                                                    
                                                        <Button disabled={instagramBusinessAccountId != '' ? '': 'true'} variant="success btn-full" type='submit' onClick={handleInstaSubmit}>
                                                            Upload to Instagram Reel
                                                        </Button>
                                                    
                                                </form>
                                            </div>


                                        </div>
                                    )
                                }
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <img className='socialIconsSmalll' src={tiktok_icon} />
                                Upload to TikTok{' '}

                                {isTiktokLoggedIn && (
                                    <a onClick={() => handleDisconnect('tiktok')} className="disconnectbtn btn btn-lg btn-link">
                                        Disconnect
                                    </a>
                                )}
                            </Accordion.Header>
                            <Accordion.Body>
                                {!isLoading && !isTiktokLoggedIn && (
                                    <button
                                        className="tiktokConnect btn btn-group-vertical btn-outline-primary"
                                        onClick={() => handleLogin('tiktok')}
                                    >
                                        <img src={connect_with_tiktok} alt="Connect with tiktok" />
                                        Connect with tiktok
                                    </button>
                                )}

                                {isTiktokLoggedIn && (
                                    <>
                                        <div className='tiktokFormAndVideoWraper'>
                                            <div className="userinfoWrapperWrap">
                                                {userInfo?.tiktok?.data && userInfo?.tiktok?.data?.creator_nickname && (
                                                    <div className="shadow userInfoCircle">
                                                        <img src={userInfo?.tiktok?.data?.creator_avatar_url} />
                                                        <h3>{userInfo?.tiktok?.data?.creator_nickname}</h3>
                                                    </div>
                                                )}

                                                <div className="videoPreview">
                                                    <VideoPlayer src={video_url} hasControls={true} />
                                                </div>
                                            </div>

                                            <form className="ss_tiktokForm">
                                                <div className="formWrapper">
                                                    <div className="validation-sorts" style={{ display: 'none' }}>
                                                        Cannot be shared. Videos shared on tiktok Reels must be between 3 seconds and 15 minutes in
                                                        length and must not exceed 1GB.
                                                    </div>
                                                    <div className="inputWrapper">
                                                        <label className="label">
                                                            Caption <p><span>{tiktokvideoTitle.length}</span>/<span>100</span></p>
                                                        </label>
                                                        <input
                                                            type="text"
                                                            className="textbox text-control"
                                                            placeholder="Add a title that describes your video"
                                                            name="tiktokTitle"
                                                            value={tiktokvideoTitle}
                                                            onChange={(e) => settiktokVideoTitle(e.target.value)}
                                                        />
                                                    </div>
                                                    <div className="inputWrapper select-wrapper">
                                                        <label className="label">
                                                            Who can view this video
                                                        </label>
                                                        <select
                                                            className="accessSpecifiers"
                                                            value={tiktokaccessSpecifier}
                                                            onChange={(e) => settiktokAccessSpecifier(e.target.value)}
                                                        >
                                                            <option value="" disabled>
                                                                Choose Privacy Status
                                                            </option>
                                                            <option
                                                                value={PRIVACY_OPTIONS.SELF_ONLY}
                                                                disabled={tiktokBrandedContent || isOptionDisabled(PRIVACY_OPTIONS.SELF_ONLY)}
                                                            >
                                                                PRIVATE
                                                            </option>
                                                            <option
                                                                value={PRIVACY_OPTIONS.PUBLIC_TO_EVERYONE}
                                                                disabled={isOptionDisabled(PRIVACY_OPTIONS.PUBLIC_TO_EVERYONE)}
                                                            >
                                                                PUBLIC
                                                            </option>
                                                            <option
                                                                value={PRIVACY_OPTIONS.MUTUAL_FOLLOW_FRIENDS}
                                                                disabled={isOptionDisabled(PRIVACY_OPTIONS.MUTUAL_FOLLOW_FRIENDS)}
                                                            >
                                                                MUTUAL FOLLOWERS & FRIENDS
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className="inputWrapper">
                                                        <label className="label">
                                                            Allow users to
                                                        </label>
                                                        <div className="checkboxInlineWrapper mt-3">
                                                            <div className="form-check form-check-inline checkboxWrapper">
                                                                <input
                                                                    disabled={userInfo?.tiktok?.data?.comment_disabled}
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="Comment"
                                                                    checked={comment}
                                                                    onChange={(e) => setComment(e.target.checked)}
                                                                />
                                                                <label className="form-check-label" htmlFor="Comment">
                                                                    Comment
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-check-inline checkboxWrapper">
                                                                <input
                                                                    disabled={userInfo?.tiktok?.data?.duet_disabled}
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="Duet"
                                                                    checked={duet}
                                                                    onChange={(e) => setDuet(e.target.checked)}
                                                                />
                                                                <label className="form-check-label" htmlFor="Duet">
                                                                    Duet
                                                                </label>
                                                            </div>

                                                            <div className="form-check form-check-inline checkboxWrapper">
                                                                <input
                                                                    disabled={userInfo?.tiktok?.data?.stitch_disabled}
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="Stitch"
                                                                    checked={stitch}
                                                                    onChange={(e) => setStitch(e.target.checked)}
                                                                />
                                                                <label className="form-check-label" htmlFor="Stitch">
                                                                    Stitch
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="discloseVideoContentWrap mt-4">
                                                        <div className="toggleWraper">
                                                            <div className="form-check form-switch">
                                                                <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
                                                                    Disclose video content
                                                                    <OverlayTrigger
                                                                        key="top"
                                                                        placement="top"
                                                                        overlay={
                                                                            <Tooltip id="tooltip-top">
                                                                                This post notifies other users that it promotes a brand, product, or service.
                                                                            </Tooltip>
                                                                        }
                                                                    >
                                                                        <img
                                                                            src={info_icon}
                                                                            alt="Info icon"
                                                                            data-bs-toggle="tooltip"
                                                                            data-bs-placement="top"
                                                                            title="Tooltip on top"
                                                                        />
                                                                    </OverlayTrigger>
                                                                    <p>
                                                                        {tiktokBrandedContent && !tiktokYourBrand ? (
                                                                            <div className="msg-tool alert alert-info mt-3">
                                                                                <img
                                                                                    src={info_icon}
                                                                                    alt="Info icon"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="top"
                                                                                    title="Tooltip on top"
                                                                                />
                                                                                <p>Your photo/video will be labeled as 'Paid partnership'</p>
                                                                            </div>
                                                                        ) : null}
                                                                        {tiktokYourBrand && !tiktokBrandedContent ? (
                                                                            <div className="msg-tool alert alert-info mt-3">
                                                                                <img
                                                                                    src={info_icon}
                                                                                    alt="Info icon"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="top"
                                                                                    title="Tooltip on top"
                                                                                />
                                                                                <p>Your photo/video will be labeled as 'Promotional content'</p>
                                                                            </div>
                                                                        ) : null}
                                                                        {tiktokBrandedContent && tiktokYourBrand ? (
                                                                            <div className="msg-tool alert alert-info mt-3">
                                                                                <img
                                                                                    src={info_icon}
                                                                                    alt="Info icon"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="top"
                                                                                    title="Tooltip on top"
                                                                                />
                                                                                <p>Your photo/video will be labeled as 'Paid partnership'</p>
                                                                            </div>
                                                                        ) : null}
                                                                    </p>
                                                                </label>
                                                                <input
                                                                    className="form-check-input float-end"
                                                                    type="checkbox"
                                                                    role="switch"
                                                                    id="flexSwitchCheckDefault"
                                                                    checked={tiktokDisclose}
                                                                    onChange={() => setTiktokDisclose(!tiktokDisclose)}
                                                                />
                                                            </div>
                                                        </div>
                                                        {tiktokDisclose && (
                                                            <div className="disclauseCheckboxes">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input float-end"
                                                                        type="checkbox"
                                                                        id="flexCheckYourBrand"
                                                                        checked={tiktokYourBrand}
                                                                        onChange={() => setTiktokYourBrand(!tiktokYourBrand)}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="flexCheckYourBrand">
                                                                        Your Brand
                                                                    </label>
                                                                    <p>You are promoting yourself or your own business. This content will be classified as Brand Organic.</p>
                                                                </div>

                                                                <div className="form-check">
                                                                    <input
                                                                        disabled={(tiktokaccessSpecifier === '' || tiktokaccessSpecifier === PRIVACY_OPTIONS.SELF_ONLY)}
                                                                        className="form-check-input float-end"
                                                                        type="checkbox"
                                                                        id="flexCheckBrandedContent"
                                                                        checked={tiktokBrandedContent}
                                                                        onChange={() => {
                                                                            setTiktokBrandedContent(!tiktokBrandedContent);
                                                                            // If Branded Content is checked, set the access specifier to PUBLIC
                                                                            if (!tiktokBrandedContent) {
                                                                                settiktokAccessSpecifier(PRIVACY_OPTIONS.PUBLIC_TO_EVERYONE);
                                                                            }
                                                                        }}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="flexCheckBrandedContent">
                                                                        Branded Content
                                                                    </label>
                                                                    <p>You are promoting another brand or a third party. This content will be classified as Branded Content.</p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="declarationWrapper">
                                                    {tiktokYourBrand && !tiktokBrandedContent ? (
                                                        <p>
                                                            By posting, you agree to TikTok's{' '}
                                                            <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noopener noreferrer">
                                                                Music Usage Confirmation
                                                            </a>
                                                            .
                                                        </p>
                                                    ) : tiktokBrandedContent && !tiktokYourBrand ? (
                                                        <p>
                                                            By posting, you agree to TikTok's{' '}
                                                            <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" target="_blank" rel="noopener noreferrer">
                                                                Branded Content Policy
                                                            </a>{' '}
                                                            and{' '}
                                                            <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noopener noreferrer">
                                                                Music Usage Confirmation
                                                            </a>
                                                            .
                                                        </p>
                                                    ) : tiktokBrandedContent && tiktokYourBrand ? (
                                                        <p>
                                                            By posting, you agree to TikTok's{' '}
                                                            <a href="https://www.tiktok.com/legal/page/global/bc-policy/en" target="_blank" rel="noopener noreferrer">
                                                                Branded Content Policy
                                                            </a>{' '}
                                                            and{' '}
                                                            <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noopener noreferrer">
                                                                Music Usage Confirmation
                                                            </a>
                                                            .
                                                        </p>
                                                    ) : (
                                                        <p>
                                                            By posting, you agree to TikTok's{' '}
                                                            <a href="https://www.tiktok.com/legal/page/global/music-usage-confirmation/en" target="_blank" rel="noopener noreferrer">
                                                                Music Usage Confirmation
                                                            </a>
                                                            .
                                                        </p>
                                                    )}
                                                </div>

                                                <Button className='btn btn-full btn-info mb-3 mt-3' type="submit" onClick={handleTiktokFeedsSubmit} disabled={publishButtonDisabled || isUploading}>
                                                    Upload
                                                </Button>

                                                <p className='mt-3'><b>Please note: </b>After publishing your content, it may take a few minutes to process and appear on your profile. Thank you for your patience.</p>

                                            </form>

                                        </div>
                                    </>
                                )}
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>

                    <Link to="/">
                        <button className='btn btn-lg btn-link mt-2'>Go back to Home</button>
                    </Link>

                </div>
            </div>






            <Modal className="ss_modal instagram_model ss_dark" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <div className="instagram_Tutrial_header">
                        <h3>Instagram connection</h3>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <InstagramTutorial />
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className="instagram_button" onClick={() => handleLogin('instagram')}>Continue</button>
                </Modal.Footer>
            </Modal>


            <Modal className="ss_modal instagram_thankyou_model ss_dark" show={thankyoushow} onHide={handleThankYouClose}>
                <Modal.Header closeButton>
                    <div className="instagram_Tutrial_header">
                        {/* <h3>Instagram</h3> */}
                    </div>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <h3>Thank you</h3>
                    <h4>Your Video has been uploaded to Instagram</h4>
                    <p><a className="btn btn-outline-success" target="_blank" href={uploadedMediaUrl}>View video on Instagram <img src={instagram_icon} /></a></p>
                </Modal.Body>
                <Modal.Footer>
                    <button type='button' className="instagram_button btn btn-danger" onClick={() => handleThankYouClose()}>Close</button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AuthSection;
