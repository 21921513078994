import { useState, useEffect, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { Card, Badge } from 'react-bootstrap';
import { IoIosMore } from 'react-icons/io'; // Importing an icon from react-icons
import { RiDeleteBinLine } from "react-icons/ri";
import { memo } from 'react';

const PROGRESS = {
  FAILED: {
    value: 100,
    message: "Failed",
    stringValue: "failed"
  },
  CANCELLED: {
    value: 0,
    message: "Cancelled",
    stringValue: "cancelled"
  },
  WAITING_IN_QUEUE: {
    value: 0,
    message: "Waiting in queue",
    stringValue: "waiting_in_queue"
  },
  FETCHING_VIDEO: {
    value: 10,
    message: "Fetching video",
    stringValue: "fetching_video"
  },
  TRANSCRIBING_AUDIO: {
    value: 20,
    message: "Analyzing video",
    stringValue: "transcribing_audio"
  },
  OPEN_AI_SUGGESTIONS: {
    value: 40,
    message: "Finding interesting segments",
    stringValue: "open_ai_suggestions"
  },
  FINALIZING_DATA: {
    value: 90,
    message: "Finalizing Data",
    stringValue: "finalizing_data"
  },
  TASK_COMPLETE: {
    value: 100,
    message: "Task complete!",
    stringValue: "task_complete"
  }
};
const UserVideo = memo(({ status, thumbnail, duration, title, date, onDelete, onClick, langFlag, langName }) => {
  console.log("Lang flag:", langFlag);
  function formatDate(dateString) {
    const options = {
      day: 'numeric',
      month: 'short'
    };
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', options);
  }


  const [showActionPallet, setShowActionPallet] = useState(false);
  const actionPalletRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionPalletRef.current && !actionPalletRef.current.contains(event.target)) {
        setShowActionPallet(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionPalletRef]);
  const toggleActionPallet = () => {
    setShowActionPallet(!showActionPallet);
  };

  const [showActions, setShowActions] = useState(false);

  const isProcessing = status && status !== "task_complete";

  const getStatusMessage = (statusValue) => {
    const statusKey = Object.keys(PROGRESS).find(key => PROGRESS[key].stringValue === statusValue);
    return statusKey ? PROGRESS[statusKey].message : "";
  };

  const statusMessage = getStatusMessage(status);

  const handleActionsClick = (event) => {
    event.stopPropagation();
    setShowActions(!showActions);
  };

  const handleDeleteClick = (event) => {
    // event.stopPropagation(); // Prevent click event from bubbling up to parent elements
    onDelete(); // Call the onDelete function passed as a prop
  };
  return (
    <>
      <div className="col">
        <Card className={`card rounded-5 bg-light border-0 ${status}`} style={{ "cursor": "pointer" }}>
          <div className="position-relative">
            <Card.Img variant="top" src={thumbnail} onClick={onClick} />
            {isProcessing && (
              <Badge onClick={onClick} pill className="position-absolute  bg-secondary" style={{
                "top": "50%",
                "left": "50%",
                "padding": "8px 14px",
                "color": "#1afd89",
                "border-radius": "6px!important",
                "background-color": "#353c49!important",
                "font-size": "15px",
                "font-weight": "normal",
                "transform": "translate(-50%,-50%)",
                "width": "90%",
                "display": "block",
                "text-wrap": "wrap",
                "line-height": "1.2"
              }}>
                {statusMessage}
              </Badge>
            )}
            <Badge pill className="position-absolute  bg-secondary" style={{ top: '10px', left: '10px', padding: '4px 9px' }}>
              {duration}
            </Badge>

            <span className="position-absolute langaugeFlagBadge" title={`Language : ${langName}`}>
              {langFlag}
            </span>
            <div
              className="position-absolute togglePalletAction"
              style={{ right: '10px', cursor: 'pointer', marginTop: '8px' }}
              onClick={toggleActionPallet}
              ref={actionPalletRef}
            >
              <IoIosMore size={24} />
              {showActionPallet && (
                <span className='deleteSpan' onClick={() =>
                  handleDeleteClick()
                }>
                  <RiDeleteBinLine /> Delete
                </span>
              )}
            </div>
          </div>
          <Card.Body>
            <Card.Title className='sCardtitle' onClick={onClick}>{title}</Card.Title>
            {<Card.Text style={{ fontSize: '14px', color: '#999999' }} onClick={onClick}>
              {formatDate(date)}
            </Card.Text>}
          </Card.Body>
        </Card >
      </div >
    </>
  );
});

export default UserVideo;
