
import { react, useEffect, useState } from 'react';
import axios from 'axios';
const YouTubeFormPreview = ({ item }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [userInfo, setUserInfo] = useState({
    google: {},
    instagram: {},
    tiktok: {}
  });
  const accessToken = item?.upload?.accessToken;

  useEffect(() => {
    console.log("userInfo on youtube preview", userInfo);
  }, [userInfo]);

  const fetchUserInfo = (platform) => {
    const token = localStorage.getItem(`${platform}Token`);
    // console.log("token", token);
    if (!token) return;

    let url;
    if (platform === 'google') {
      const googleToken = localStorage.getItem('googleToken');
      url = `${apiUrl}/auth/userinfo?access_token=${googleToken}`;
    } else if (platform === 'instagram') {
      const instagramToken = localStorage.getItem('instagramToken');
      url = `${apiUrl}/auth/instagram/userinfo?access_token=${instagramToken}`;
    } else if (platform === 'tiktok') {
      url = `${apiUrl}/auth/tiktok/userinfo?access_token=${token}`;
    }

    axios.get(url)
      .then(response => {
        // console.log("p the", userInfo);
        setUserInfo(prevState => ({
          ...prevState,
          [platform]: response.data
        }));
      })
      .catch(error => {
        console.error(`Error fetching ${platform} user info:`, error);
      });
  };

  useEffect(() => {
    fetchUserInfo('google');
  }, []);

  return (
    <div className="formWrapperPreviewPast">
      <div className="formWrapper">
        <div className='ss_flex_wrapper'>
          <div className='ss_flex_left d-none'>
            Left box here..
          </div>
          <div className='ss_flex_right'>
            <div className='userWrapperCommon'>
              <div className='userProfilePic'>
                <img className='profilePic' src={userInfo?.google?.picture} />
                <div className='plateformIcon'>
                  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="24" height="24" rx="12" fill="#FF0000"></rect><path d="M20.221 7.87825C20.0233 7.1439 19.4443 6.56489 18.7099 6.36718C17.3683 6 12.0019 6 12.0019 6C12.0019 6 6.63543 6 5.29382 6.35306C4.57359 6.55077 3.98045 7.1439 3.78274 7.87825C3.42969 9.21986 3.42969 12.0019 3.42969 12.0019C3.42969 12.0019 3.42969 14.7981 3.78274 16.1256C3.98045 16.86 4.55946 17.439 5.29382 17.6367C6.64955 18.0039 12.0019 18.0039 12.0019 18.0039C12.0019 18.0039 17.3683 18.0039 18.7099 17.6508C19.4443 17.4531 20.0233 16.8741 20.221 16.1397C20.5741 14.7981 20.5741 12.0161 20.5741 12.0161C20.5741 12.0161 20.5882 9.21986 20.221 7.87825Z" fill="white"></path><path d="M10.293 14.5721L14.7556 12.0019L10.293 9.43164V14.5721Z" fill="#FF0000"></path></svg>
                </div>
              </div>
              <p>{userInfo?.google?.name}</p>
            </div>
            <div className="inputWrapper">
              <input
                type="text"
                className="textbox text-control"
                placeholder="Enter YouTube Video Title"
                value={item?.upload?.videoData?.title}
                disabled
              />
            </div>

            <div className="inputWrapper">
              <textarea
                className="textarea text-control"
                placeholder="Enter YouTube Video Description"
                value={item?.upload?.videoData?.description}
                disabled
              />
            </div>

            <div className="radio-wrappe ss-custom-radio">
              <div>
                <input
                  type="radio"
                  id="public"
                  name="accessSpecifier"
                  value="public"
                  checked={item?.upload?.videoData?.accessSpecifier === 'public'}
                  disabled
                />
                <label htmlFor="public">Public</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="private"
                  name="accessSpecifier"
                  value="private"
                  checked={item?.upload?.videoData?.accessSpecifier === 'private'}
                  disabled
                />
                <label htmlFor="private">Private</label>
              </div>
              <div>
                <input
                  type="radio"
                  id="unlisted"
                  name="accessSpecifier"
                  value="unlisted"
                  checked={item?.upload?.videoData?.accessSpecifier === 'unlisted'}
                  disabled
                />
                <label htmlFor="unlisted">Unlisted</label>
              </div>
            </div>
          </div>
        </div>

      </div >

    </div>

  );
};

export default YouTubeFormPreview;