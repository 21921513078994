import React, { useRef, useEffect } from 'react';

const Accordion = ({ isActive, onToggle, children }) => {
  const contentRef = useRef(null);

  // useEffect(() => {
  //   if (isActive) {
  //     contentRef.current.style.height = contentRef.current.scrollHeight + 'px';
  //   } else {
  //     contentRef.current.style.height = '0';
  //   }
  // }, [isActive]);

  return (
    <div className={`ss_accordion ${isActive ? 'ss_active_acc' : ''}`} onClick={onToggle}>
      <div className="ss_accordion-content" ref={contentRef}>
        {children}
      </div>
      {/* <div className="ss_accordion-toggle" onClick={onToggle}>
        <span>{isActive ? '-' : '+'}</span>
      </div> */}
    </div>
  );
};

export default Accordion;