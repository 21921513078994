import { useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import Short from './Short';

const ShortListModal = ({ userId, show, onHide, onShow, requestId, ShortsList, ShowShortPreview, handleBackClick }) => {
    const { shorts = [] } = ShortsList || {};

    const handleShowShortPreview = useCallback((requestId, shortId, shortData) => {
        onHide(true);
        ShowShortPreview(requestId, shortId, shortData);
    }, [onHide, ShowShortPreview]);

    return (
        <>
            {show && (
                <Modal id="ss_modal_shorts_uploader" show={show} onHide={onHide} centered className='dcs-iframe-only ss_modal_shorts_uploader modal-lg shortsListModal'>
                    <Modal.Header closeButton>
                        <div className='ssHeaderWraper'>
                            <div className="headingWrapper">
                                <div className='backIconWrapper' onClick={handleBackClick}>
                                    <svg width="16" height="16" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor" className="hover:text-accent-foreground cursor-pointer"><path d="M15 6L9 12L15 18" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                </div>
                                <h3>Select clip</h3>
                            </div>
                            <p>Select a clip to preview and confirm.</p>
                        </div>
                    </Modal.Header>

                    <Modal.Body>
                        <div className='draftSection'>
                            <div className="g-4 row row-cols-md-4 row-cols-sm-3 row-cols-2">
                                {shorts.map((short) => (
                                    <Short key={short.id} item={short} requestId={requestId} handleShowShortPreview={handleShowShortPreview} />
                                ))}
                            </div>
                        </div>
                    </Modal.Body>


                </Modal>
            )}
        </>
    );
};

export default ShortListModal;